import React from 'react'
import ausbg from '../assets/img/gerbg.jpg'
import ausflag from '../assets/flags/Germany.jpg'
import Gercomponent from './Gercomponent'

export default function Gerpage() {
  return (
    <>
          {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ausbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'top',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Why Study In Germany ?</h1>
		<ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> International Exposure</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Quality Education</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Funding Opportunities </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Wide Choice of Programs</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Economic Power </li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Practical Experience</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Low Tuition Fees</li>
		</ul>
            <img src={ausflag} className='mt-2 mx-4' style={{width:'80px'}}/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Why to Study in Germany ?</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container">
    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Germany</span></h4>
    </div>
    <Gercomponent/>

    

    </div>



    </>
  )
}
