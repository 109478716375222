import React from 'react'

export default function Canadatraining() {
  return (
    <>
    <div className="accordion" id="accordionExample">
    <div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyNine" aria-expanded="false" aria-controls="collapseThirtyNine">
    Why Canada?
    </button>
  </h2>
  <div id="collapseThirtyNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <h6>PNP – Provincial Nominee Program –</h6>
      <p>Most Provinces and Territories in Canada have an  agreement with the Govt. of Canada that allows them to nominate immigrants who  wish to settle in that province. Provincial Nomination is an important fast‐track option  for Canadian Permanent Residency. For e.g. if a student studies a course from any  institution from Saskatchewan, then he can apply for PR after completion of the course  under provincial nominee program if the province is participating at that point of time.</p>
      <p>Ontario, Alberta, British Columbia, Manitoba, New Brunswick, Newfoundland and  Labrador, Northwest Territories, Nova Scotia, Prince Edward Island, Saskatchewan and  Yukon are  the provinces which periodically participate in the Provincial Nominee  Program (PNP). It accelerates the Permanent Resident application process for skilled  and /or experienced workers, experienced business persons and their family members  who want to settle in these provinces permanently.</p>
      <p>Parents can be sponsored to permanently reside in Canada. Besides a separate  category of visa “Super Visa” is there for visiting parents and grandparents of  permanent residents or citizens of Canada which is valid for upto ten years and allows  parents or grandparents to remain in Canada for upto 24 months at a time without the  need for renewal of their status.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseForty" aria-expanded="false" aria-controls="collapseForty">
    Canada Express Entry
    </button>
  </h2>
  <div id="collapseForty" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-2">Express Entry is a selection system for Canadian immigration, designed to select skilled  workers for immigration to Canada. It is a complete electronic process involving the  federal government, provincial governments and Canadian employers.</li>
        <li className="my-2">Express Entry selects skilled immigrants through its point based system that allocates  points to candidates, based on their key profile factors, i.e. age, education, work  experience, language skills, etc.</li>
        <li className="my-2">There are a total of 1,200 points available under the Comprehensive Ranking System.</li>
        <li className="my-2">The Express entry involves eligible candidates to make an expression of interest in  immigrating to Canada who are all entered in the Express Entry pool.</li>
        <li className="my-2">Some of these candidates in the pool who are eligible are issued invitations to apply for  Canadian permanent residence and take the process forward.</li>
        <li className="my-2">Express Entry Canada has undergone changes on 19th November 2016 in line with the  demands of International students</li>
        <li className="my-2">Now International students have been allotted additional points based on their level  of education in Canada ranging from 0‐30 points.</li>
      </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortyOne" aria-expanded="false" aria-controls="collapseFortyOne">
    Work Permit for Students
    </button>
  </h2>
  <div id="collapseFortyOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
     <p>Study permits will automatically authorize the holder to work on/off‐campus for up to 20  hours per week during the academic session and full‐time (i.e. 40 hours per week) during  scheduled breaks without the need to apply for a separate work permit. The study permit  holder should be pursuing academic, vocational or professional training of six months or  more that leads to a degree, diploma or certificate at a Designated Learning Institution.</p>
     <p>Co‐op & Internship Programmes : Many of the programs have inbuilt internships of one  or two semesters which provides an opportunity for the student to get first hand  exposure and experience in Industry relating to his or her area of study.</p>
    </div>
  </div>
</div>

</div>
    </>
  )
}
