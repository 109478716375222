import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <>
        <div className="container-fluid bg-light2 text-dark footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
        <h2 className="text-primary2 my-3">Ease & Expertise Of <br/>
        Overseas Education</h2>

        </div>
 
        <div className="container py-5">
            <div className="row g-5">
                <div className="col-lg-4 col-md-6 d-flex flex-column">
                    <h4 className="text-primary2 mb-3">Main Links</h4>
                    <Link className="text-dark my-1 px-0" to="/">Home</Link>
                    <a className="text-dark my-1 px-0" target='_blank' href="https://www.globedwise.com/events">Upcoming Events</a>
                    <Link className="text-dark my-1 px-0" to="/partners-institutions">Partners Institutions</Link>
                    <Link className="text-dark my-1 px-0" to="/canada-program-availability">Canada Program Availability</Link>
                    <Link className="text-dark my-1 px-0" to="/check-program-availability">Check Program Availability</Link>
                    <Link className="text-dark my-1 px-0" to="/admission-evaluator-tool">Admission Evaluator Tool</Link>
                </div>

                <div className="col-lg-6 col-md-6">
                    <h4 className="text-primary2 mb-3">Our Locations</h4>
                    <p className="mb-3"><a className="text-primary2 font-weight-bold" target="_blank" href="https://goo.gl/maps/zgD3Jy5bnKVvFi8N9"><i className="fa fa-map-marker-alt me-1 text-primary2"></i>Prashant Vihar, New Delhi <br />
                    <span className="text-dark">A-54, Prashant Vihar, Opposite Lancer Convent School, Sector – 14, Rohini,</span> 
                    </a> </p>
                    <p className="mb-3"><a className="text-primary2 font-weight-bold" target="_blank" href="https://goo.gl/maps/ggZJbkNmuahBXmCH6"><i className="fa fa-map-marker-alt me-1 text-primary2"></i>Rajouri Garden, New Delhi <br />
                    <span className="text-dark">J-4/1, Rajouri Garden, New Delhi – 110027, India</span>
                    </a> </p>
                    <p className="mb-3"><a className="text-primary2 font-weight-bold" target="_blank" href="https://goo.gl/maps/mvqPQhazxwZzKCFj6"><i className="fa fa-map-marker-alt me-1 text-primary2"></i>Vijay Nagar, New Delhi <br />
                   <span className="text-dark"> H-15, Vijay Nagar, Delhi University Area, Delhi – 110009, India</span></a> </p>
                    <p className="mb-3"><a className="text-primary2 font-weight-bold" target="_blank" href="https://goo.gl/maps/UX2MipmyjoG8qVA47"><i className="fa fa-map-marker-alt me-1 text-primary2"></i>Chandigarh, <br />
                   <span className="text-dark"> SCO 315-316, First Floor, Sector 35B, Chandigarh -160022, India</span>
                    </a> </p>
                </div>
              
                <div className="col-lg-2 col-md-6">
                <h4 className="text-primary2 mb-3">Destinations</h4>
                    <Link className="btn px-0" to="/">United States</Link><br />
                    <Link className="btn px-0" to="/">United Kingdom</Link><br />
                    <Link className="btn px-0" to="/">Canada</Link><br />
                    <Link className="btn px-0" to="/">New Zealand</Link><br />
                    <Link className="btn px-0" to="/">Australia</Link><br />
                    <Link className="btn px-0" to="/">Europe</Link><br />
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                        &copy; <a className="border-bottom text-danger" href="javascript:void(0)">Copyright © 2024 GlobEDwise</a>, All Right Reserved.
                    </div>
                   
                </div>
            </div>
        </div>
    </div>

    
    </>
  )
}
