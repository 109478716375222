import React from 'react'

export default function Canada6sec() {
  return (
    <>
            <div className="accordion" id="accordionExample">

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortySeven" aria-expanded="false" aria-controls="collapseFortySeven">
    If a student changes institution, is a new study permit required?
    </button>
  </h2>
  <div id="collapseFortySeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <h6>Changing your school or program</h6>
      <p>Before you change your school or program, you must meet all the conditions of your study permit. This means you must be:</p>
      <ul>
        <li className="my-2">Enrolled at a <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/study-permit/prepare/designated-learning-institutions-list.html">designated learning institution (DLI)</a></li>
        <li className="my-2">Actively studying while you’re in Canada</li>
      </ul>
      <p>You need to contact IRCC if you:</p>
      <ul>
        <li className="my-2">Are a <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/study-canada/change-schools.html#post-secondary">post-secondary student changing schools</a></li>
        <li className="my-2">Have a co-op work permit and <a href="https://ircc.canada.ca/english/helpcentre/answer.asp?qnum=1473&top=15#schools">you’ve changed post-secondary schools to another co-op program</a></li>
        <li className="my-2">Need to <a href="https://www.canada.ca/en/immigration-refugees-citizenship/services/application/application-forms-guides/guide-5552-applying-change-conditions-extend-your-stay-canada-student.html">change conditions on your study permit</a></li>
      </ul>
      <h6>They may ask you to confirm your student status</h6>
      <p>If you have a study permit, you may <a href="https://ircc.canada.ca/english/helpcentre/answer.asp?qnum=1205&top=31">receive an email from us</a>. The email address will end in “@cic.gc.ca.” You must follow the instructions in that email, by the date indicated, to confirm you’re enrolled and actively studying in Canada.</p>
      <h6>If you change schools and don’t tell us</h6>
      <p>Your old school will report you as not being registered to study. If this happens:</p>
      <ul>
        <li className="my-2">you’re breaking the conditions of your study permit</li>
        <li className="my-2">you may be asked to leave the country</li>
        <li className="my-2">this may prevent you from coming to Canada in the future</li>
        <li className="my-2">For example, you may not be approved for another study or work permit in the future.</li>
      </ul>
      <h6>Changing your DLI if you’re outside Canada</h6>
      <p>If you change your DLI before coming to Canada and you don’t let us know, you won’t be allowed to board your flight.</p>
      <h6>If your application is still in progress</h6>
      <p>You can tell us you changed your DLI by submitting a new letter of acceptance using the IRCC web form.</p>
      <h6>If your application has been approved</h6>
      <p>If your application for a study permit has been approved and you change your DLI, you must submit a new study permit application with a new letter of acceptance. You must also pay all the fees for the new application.</p>
      <h6>Moving to the next education level</h6>
      <p>If your study permit is still valid, you don’t need to change or apply for a new permit if you’re moving between school levels. This could be:</p>
      <ul>
        <li className="my-2">primary to high school</li>
        <li className="my-2">high school to post-secondary</li>
        <li className="my-2">post-secondary to high school</li>
        <li className="my-2">any other move between school levels</li>
      </ul>
      <p>If your study permit will expire, you need to apply to extend it.</p>
      <p>If your permit has already expired, you must restore your status as a student at the same time as you apply to extend your study permit. You must apply to restore your status within 90 days of losing it. You can’t continue or restart your studies until you have status as a student again and we’ve extended your study permit.</p>
      <h6>Post-secondary students changing schools</h6>
      <p>If you change post-secondary schools, the school you plan to change to must also be a DLI. Please check the DLI list and confirm your new school is a DLI before you commit to changing schools.</p>
      <p>You must tell us each time you change post-secondary schools. Changing the post-secondary school on your account is a free service.</p>
    </div>
  </div>
</div>

</div>
    </>
  )
}
