import React from 'react'

export default function Module3() {
  return (
    <>
    <div className="accordion" id="accordionExample">
    <div className="accordion-item">
  <h2 className="accordion-header" id="heading28">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse28" aria-expanded="false" aria-controls="collapse28">
    Post Study Work Visa
    </button>
  </h2>
  <div id="collapse28" className="accordion-collapse collapse" aria-labelledby="heading28" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>All international students who have graduated from a Canadian post‐secondary institution You are eligible for Post Study Work Visa to gain valuable Canadian work experience.</p>
      <p>To be eligible for a Post Study Work Visa, international students</p>
      <ul>
        <li className="my-1">Should have studied full‐time for at least eight months preceding the completion of  their program and have graduated from a Designated Learning Institution.</li>
        <li className="my-1">Must have completed and passed the program of study and received written  notification that they are eligible to obtain their degree, diploma, or certificate.</li>
        <li className="my-1">Must apply for a post study  work permit within 90 days of receiving written  confirmation from their academic institution that they have met the requirements of  their program.</li>
        <li className="my-1">Must have a valid study permit when applying for the post study work permit.</li>
        <li className="my-1">The Post Study Work Visa provides no restrictions on the type of employment and requirement of a job offer.</li>
      </ul>
      <p><strong>1 year Study – 1 year of Post Study Work Visa</strong></p>
      <p><strong>2 years Study – upto 3 years of Post Study Work Visa</strong></p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading29">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse29" aria-expanded="false" aria-controls="collapse29">
    SDS‐ Study Direct Stream
    </button>
  </h2>
  <div id="collapse29" className="accordion-collapse collapse" aria-labelledby="heading29" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>Canada proposes to launch the Study Direct Stream (SDS) Program which streamlines tSDS process for  Indian students to study at colleges and universities in Canada.</p>
      <p><strong>SDS Program Requirements:</strong></p>
      <p>Students applying for a program under SDS must meet the following eligibility criteria:</p>
        <ul>
            <li className="my-1">Admission to a full‐time program in a publicly‐funded post‐secondary institution such as universities, colleges, institutes or CEGEP</li>
            <li className="my-1">An IELTS score with a minimum band of 6 for each ability (Listening, Reading, Writing and Speaking) taken within past two years</li>
            <li className="my-1">Purchase of a special Guaranteed Investment Certificate (GIC) from Scotiabank of CAD$10,000 to  cover living expenses for the first year of stay in Canada</li>
            <li className="my-1">Proof of payment of the tuition fee for one (1) year</li>
            <li className="my-1">Upfront medical examination at least one week before submission of the application.</li>
        </ul>

    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading30">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse30" aria-expanded="false" aria-controls="collapse30">
    Major advantages of applying under SDS:
    </button>
  </h2>
  <div id="collapse30" className="accordion-collapse collapse" aria-labelledby="heading30" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-1">Streamlined visa process.</li>
        <li className="my-1">Minimal financial documentation.</li>
        <li className="my-1">Faster visa processing and outcomes with standard turn around time of 20 calendar days or less after submission of complete visa application and biometrics.</li>
      </ul>
      <p>Students not meeting the requirements of SDS may file their visa under General Category. Processing time for General Category visas range from 45 to 60 days.</p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading31">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse31" aria-expanded="false" aria-controls="collapse31">
    What’s a GIC (Guaranteed Investment Certificate)?
    </button>
  </h2>
  <div id="collapse31" className="accordion-collapse collapse" aria-labelledby="heading31" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>A GIC is an investment account that offers a guaranteed interest rate over a fixed period of time. With the Scotiabank AND CIBC Student GIC Program you can invest between $10,000 CAD – $50,000 CAD.</p>
      <ul>
        <li className="my-1">Wire transfer $10,200 CAD to Scotiabank in Canada. $10,000 CAD will be deposited into your Scotiabank Investment Account and $200 CAD will pay for the program fee.</li>
        <li className="my-1">Have $2,000 CAD plus any interest deposited into your student bank account when you arrive in Canada.</li>
        <li className="my-1">Receive a portion of your investment back each month for 12 months – approximately $667 CAD each month.</li>
        <li className="my-1">After 12 months, you’ll have received your $10,000 CAD investment back, plus interest.</li>
      </ul>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading32">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse32" aria-expanded="false" aria-controls="collapse32">
    Points to Remember for Successful Offers and Visas
    </button>
  </h2>
  <div id="collapse32" className="accordion-collapse collapse" aria-labelledby="heading32" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-1">Do not apply for students who do not meet the IELTS criteria. Minimum 6.0  with no band less than 6.0 for UG and overall 6.5 with no band less than 6.0 for  PG.</li>
        <li className="my-1">Do not apply for students who have several failed courses, large gaps of 5 or more years in studies (regardless of their work experience) and inconsistency in program choice with past studies. Course choice must be relevant.</li>
        <li className="my-1">Good academics of the students: We have seen higher rate of visa approval for  the students having 60% and above throughout their academic  qualifications.</li>
        <li className="my-1">Also we have noticed that if a student has low academics as well as a gap of 2‐5 years after completion of last studies, the case is weak and visa may be refused.</li>
      </ul>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading33">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse33" aria-expanded="false" aria-controls="collapse33">
    What is the difference between S1 and SW visa?
    </button>
  </h2>
  <div id="collapse33" className="accordion-collapse collapse" aria-labelledby="heading33" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p><strong>➢ S1 is study only Visa. </strong></p>
      <p><strong>➢ SW1 is study and work visa. (Usually given to those students who are going for a Co-op program)</strong></p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading34">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse34" aria-expanded="false" aria-controls="collapse34">
    Who all can sponsor a student for Canada visa?
    </button>
  </h2>
  <div id="collapse34" className="accordion-collapse collapse" aria-labelledby="heading34" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p><strong>➢ Student’s parents should ideally sponsor them for Visa. </strong></p>
      <p><strong>➢ The sponsor can also be the students themselves, spouse, siblings, paternal and maternal grandparents.</strong></p>
      <p><strong>➢ Third party sponsorship should not be more than 30% of the entire expense</strong></p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading35">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse35" aria-expanded="false" aria-controls="collapse35">
    What is CAQ?
    </button>
  </h2>
  <div id="collapse35" className="accordion-collapse collapse" aria-labelledby="heading35" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-1">Québec Certificate of Acceptance (CAQ) – International students whose program in Québec lasts more than six months should be authorized to study by the ministère de l’Immigration, de la Diversité et de l’Inclusion (MIDI). </li>
        <li className="my-1">If any student is applying for any colleges in Quebec, it is necessary that they first apply for a CAQ and then for Visa.</li>
        <li className="my-1">There is an online application process for CAQ and the turn around time to receive the same is 5 -7 weeks. </li>
        <li className="my-1">Mentioned below is the link to apply for a CAQ https://www.immigration-quebec.gouv.qc.ca/en/electronic-services/caqelectronic/index.html </li>
        <li className="my-1">Application fee for the same is CAD 117</li>
      </ul>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading36">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse36" aria-expanded="false" aria-controls="collapse36">
    Submitting a winning Application
    </button>
  </h2>
  <div id="collapse36" className="accordion-collapse collapse" aria-labelledby="heading36" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-1">Completed Application Form</li>
        <li className="my-1">Academic Records</li>
        <li className="my-1">Standardized Examination Scores</li>
        <li className="my-1">Work Experience details if any</li>
        <li className="my-1">Application Fees if applicable</li>
      </ul>
      <p>No SOP or Recommendation Letters  required for Community Collegesy Colleges</p>
    </div>
  </div>
</div>
<div className="accordion-item">
  <h2 className="accordion-header" id="heading37">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse37" aria-expanded="false" aria-controls="collapse37">
    Counseling Tips
    </button>
  </h2>
  <div id="collapse37" className="accordion-collapse collapse" aria-labelledby="heading37" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-1">Identify the profile suitability for Canada.</li>
        <li className="my-1">Do not accept students below 50% for  Colleges* and below 65% for Masters in the University.</li>
        <li className="my-1">Declare in advance students with long gaps in their Education and multiple backlogs.</li>
        <li className="my-1">Check English Language ability.</li>
        <li className="my-1">Inform the student about the benefits of studying in Canada  as compared to other countries.</li>
        <li className="my-1">Most Important – Talk about PR Opportunity, Part time work ,full time job opportunities.</li>
        <li className="my-1">Talk about the lesser investment and higher gains (Less fees).</li>
      </ul>
    </div>
  </div>
</div>

</div>

    </>
  )
}
