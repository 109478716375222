import React from 'react'
import { Accordion } from 'react-bootstrap'

export default function Usintro() {
  return (
    <>
    {/* <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>About Unites States of America</Accordion.Header>
        <Accordion.Body>
        <ul>
        <li>Capital – Washington, D.C.</li>
        <li>Largest city – New York City</li>
        <li>National language – English</li>
        <li>Currency – United States dollar ($) (USD) ( 1 USD is equivalent to 81.40 Rs approx.)</li>
        <li>Popular Religion – Christian</li>
        <li>President – Joe Biden</li>
        <li>Vice President – Kamala Harris</li>
        <li>The United States of America is a union of 50 states in North America.</li>
        <li>It is the world’s largest economy.</li>
        <li>Most popular states:- Illinois, California, Massachusetts, New York, Texas, Pennsylvania.</li>
        <li>Most popular cities:- New York City, Los Angeles, Pittsburgh, San Francisco, Stanford,</li>
     </ul>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion> */}
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      About Unites States of America
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
     <ul>
        <li>Capital – Washington, D.C.</li>
        <li>Largest city – New York City</li>
        <li>National language – English</li>
        <li>Currency – United States dollar ($) (USD) ( 1 USD is equivalent to 81.40 Rs approx.)</li>
        <li>Popular Religion – Christian</li>
        <li>President – Joe Biden</li>
        <li>Vice President – Kamala Harris</li>
        <li>The United States of America is a union of 50 states in North America.</li>
        <li>It is the world’s largest economy.</li>
        <li>Most popular states:- Illinois, California, Massachusetts, New York, Texas, Pennsylvania.</li>
        <li>Most popular cities:- New York City, Los Angeles, Pittsburgh, San Francisco, Stanford,</li>
     </ul>
    
    <img src="/img/usmap.png" className='img-fluid' alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
        Climate USA
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Southwest – The Southwest has a hot desert climate and covers cities like Phoenix, Las The average temperature in summers would be around 40°C to 25°C; in winters it would be around 18°C to 4°C.</li>
            <li>Southeast – The southeast has a warm to a hot, humid climate and covers cities like Houston, New Orleans, Atlanta, and Charleston. The average temperature in summers is around 35°C to 25°C; in winters it is around 10°C to 0°C.</li>
            <li>Midwest – The Midwest, Northern Plains, Great Lakes, and New England regions have a humid climate and cover cities like Wisconsin, Michigan, and Ohio. The average temperature in summers is around 28°C to 15°C; in winters it is around 0°C to -9°C.</li>
            <li>Atlantic Coast – The central plains and East Coast has a temperate humid climate and covers cities like Louis, Cincinnati, and NYC. The average temperature in summers is around 30°C to 18°C; in winters it is around 4°C to -3°C.</li>
            <li>Pacific Northwest – The Pacific Northwest has an oceanic climate and covers cities like Seattle, and Portland. The average temperature in summers is around 30°C to 18°C; in winters it is around 4°C to -3°C.</li>
            <li>The United States is divided into six time zones: Hawaii-Aleutian time, Alaska time, Pacific time, Mountain time, Central time and Eastern time</li>
        </ul>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Popular States in USA
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Ohio : Tournaments location, Health Care Industry.</li>
            <li>Massachusetts: Highly developed, Job opportunities & Education hub.</li>
            <li>New York : Highly developed, Commercial Hub, Student city, Job opportunities, Niagara Falls.</li>
            <li>Virginia : Hub for IT sector, Job opportunities.</li>
            <li>Florida : Aviation related programs, Travel & Tourism, International Trade. Florida has a lot of undeveloped land, which helps the construction industry to flourish.</li>
            <li>Texas: Low tuition Charges Instate tuition fee to international students.</li>
            <li>California : Silicon Valley, Hollywood, Disney World, Hub for IT Aviation related program, low tuition fees.</li>
            <li>Oregon : Manufacturing Industry because of raw material.</li>
        </ul>
        <h6>The following table lists the capital and largest city of every state in the United States.</h6>

        <table className="table table-striped">
    <thead>
      <tr>
        <th>State</th>
        <th>Capital</th>
        <th>Largest City</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Alabama</td>
        <td>Montgomery</td>
        <td>Birmingham</td>
      </tr>
      <tr>
        <td>Alaska</td>
        <td>Juneau</td>
        <td>Anchorage</td>
      </tr>
      <tr>
        <td>Arizona</td>
        <td>Phoenix</td>
        <td>Phoenix</td>
      </tr>
      <tr>
        <td>Arkansas</td>
        <td>Little Rock</td>
        <td>Little Rock</td>
      </tr>
      <tr>
        <td>California</td>
        <td>Sacramento</td>
        <td>Los Angeles</td>
      </tr>
      <tr>
        <td>Colorado</td>
        <td>Denver</td>
        <td>Denver</td>
      </tr>
      <tr>
        <td>Connecticut</td>
        <td>Hartford</td>
        <td>Bridgeport</td>
      </tr>
      <tr>
        <td>Delaware</td>
        <td>Dover</td>
        <td>Wilmington</td>
      </tr>
      <tr>
        <td>Florida</td>
        <td>Tallahassee</td>
        <td>Jacksonville</td>
      </tr>
      <tr>
        <td>Georgia</td>
        <td>Atlanta</td>
        <td>Atlanta</td>
      </tr>
      <tr>
        <td>Hawaii</td>
        <td>Honolulu</td>
        <td>Honolulu</td>
      </tr>
      <tr>
        <td>Idaho</td>
        <td>Boise</td>
        <td>Boise</td>
      </tr>
      <tr>
        <td>Illinois</td>
        <td>Springfield</td>
        <td>Chicago</td>
      </tr>
      <tr>
        <td>Indiana</td>
        <td>Indianapolis</td>
        <td>Indianapolis</td>
      </tr>
      <tr>
        <td>Iowa</td>
        <td>Des Moines</td>
        <td>Des Moines</td>
      </tr>
      <tr>
        <td>Kansas</td>
        <td>Topeka</td>
        <td>Wichita</td>
      </tr>
      <tr>
        <td>Kentucky</td>
        <td>Frankfort</td>
        <td>Louisville</td>
      </tr>
      <tr>
        <td>Louisiana</td>
        <td>Baton Rouge</td>
        <td>New Orleans</td>
      </tr>
      <tr>
        <td>Maine</td>
        <td>Augusta</td>
        <td>Portland</td>
      </tr>
      <tr>
        <td>Maryland</td>
        <td>Annapolis</td>
        <td>Baltimore</td>
      </tr>
      <tr>
        <td>Massachusetts</td>
        <td>Boston</td>
        <td>Boston</td>
      </tr>
      <tr>
        <td>Michigan</td>
        <td>Lansing</td>
        <td>Detroit</td>
      </tr>
      <tr>
        <td>Minnesota</td>
        <td>St. Paul</td>
        <td>Minneapolis</td>
      </tr>
      <tr>
        <td>Mississippi</td>
        <td>Jackson</td>
        <td>Jackson</td>
      </tr>
      <tr>
        <td>Missouri</td>
        <td>Jefferson City</td>
        <td>Kansas City</td>
      </tr>
      <tr>
        <td>Montana</td>
        <td>Helena</td>
        <td>Billings</td>
      </tr>
      <tr>
        <td>Nebraska</td>
        <td>Lincoln</td>
        <td>Omaha</td>
      </tr>
      <tr>
        <td>Nevada</td>
        <td>Carson City</td>
        <td>Las Vegas</td>
      </tr>
      <tr>
        <td>New Hampshire</td>
        <td>Concord</td>
        <td>Manchester</td>
      </tr>
      <tr>
        <td>New Jersey</td>
        <td>Trenton</td>
        <td>Newark</td>
      </tr>
      <tr>
        <td>New Mexico</td>
        <td>Santa Fe</td>
        <td>Albuquerque</td>
      </tr>
      <tr>
        <td>New York</td>
        <td>Albany</td>
        <td>New York City</td>
      </tr>
      <tr>
        <td>North Carolina</td>
        <td>Raleigh</td>
        <td>Charlotte</td>
      </tr>
      <tr>
        <td>North Dakota</td>
        <td>Bismarck</td>
        <td>Fargo</td>
      </tr>
      <tr>
        <td>Ohio</td>
        <td>Columbus</td>
        <td>Columbus</td>
      </tr>
      <tr>
        <td>Oklahoma</td>
        <td>Oklahoma City</td>
        <td>Oklahoma City</td>
      </tr>
      <tr>
        <td>Oregon</td>
        <td>Salem</td>
        <td>Portland</td>
      </tr>
      <tr>
        <td>Pennsylvania</td>
        <td>Harrisburg</td>
        <td>Philadelphia</td>
      </tr>
      <tr>
        <td>Rhode Island</td>
        <td>Providence</td>
        <td>Providence</td>
      </tr>
      <tr>
        <td>South Carolina</td>
        <td>Columbia</td>
        <td>Columbia</td>
      </tr>
      <tr>
        <td>South Dakota</td>
        <td>Pierre</td>
        <td>Sioux Falls</td>
      </tr>
      <tr>
        <td>Tennessee</td>
        <td>Nashville</td>
        <td>Memphis</td>
      </tr>
      <tr>
        <td>Texas</td>
        <td>Austin</td>
        <td>Houston</td>
      </tr>
      <tr>
        <td>Utah</td>
        <td>Salt Lake City</td>
        <td>Salt Lake City</td>
      </tr>
      <tr>
        <td>Vermont</td>
        <td>Montpelier</td>
        <td>Burlington</td>
      </tr>
      <tr>
        <td>Virginia</td>
        <td>Richmond</td>
        <td>Virginia Beach</td>
      </tr>
      <tr>
        <td>Washington</td>
        <td>Olympia</td>
        <td>Seattle</td>
      </tr>
      <tr>
        <td>West Virginia</td>
        <td>Charleston</td>
        <td>Charleston</td>
      </tr>
      <tr>
        <td>Wisconsin</td>
        <td>Madison</td>
        <td>Milwaukee</td>
      </tr>
      <tr>
        <td>Wyoming</td>
        <td>Cheyenne</td>
        <td>Cheyenne</td>
      </tr>
    </tbody>
  </table>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Why study in USA
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
			<p><i className="fa fa-check-circle"></i> Worldwide Recognition</p>
			<p><i className="fa fa-check-circle"></i> Academic Flexibility</p>
			<p><i className="fa fa-check-circle"></i> Funding Opportunities </p>
			<p><i className="fa fa-check-circle"></i> Research & Technology</p>
			<p><i className="fa fa-check-circle"></i> Global Opportunities </p>
			<p><i className="fa fa-check-circle"></i> State of the Art Infrastructure</p>
			<p><i className="fa fa-check-circle"></i> World Class Faculty</p>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Popular Jobs in USA
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Technology and health care were the two industries with the most job openings, plus the highest pays.Looking at the data from the survey it is clear, Medicine and Computer Science are the most popular jobs nationwide.</p>
       <ul>
        <li>Being a Physician/doctor is the most prestigious and well-paying job in US with the base salary starting from $212,000 per year. However, medical studies are really tough and passing USMLE is mandatory to get the ‘license to practice’ in the US.</li>
        <li>At number two most popular job is Pharmacy Manager, but it has a comparatively smaller number of job openings per yea</li>
        <li>The third most popular among the high paying job is that of the Software Architect.</li>
        <li>So clearly those students looking for careers in Medicine and Computer Sciences benefit the most in the US after they finish their courses.</li>
       </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Tuition + Living Cost
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Tuition Fees for Bachelor’s around USD 20000- 30000 per year (approx. 16L to 25L), few public universities may have lower fees and few private universities will have higher fees</li>
            <li>Tuition Fees for Master’s around USD 25000-35000 per year (approx. 20L to 30L)</li>
            <li>Top ranked universities have higher fees than this</li>
            <li>MBA/Business Analytics programs having higher fees around 35L to 40L per year Living cost is around 8-10L per year, depending on the cost of living of the city. Few cities like New York City, Chicago, Tempe, San Francisco, Los Angeles, Dallas</li>
        </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Living Expenses
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Estimated living cost for the US is around $10000 to $12000 per year, which averages around $700 to $1000 per month. This includes your accommodation costs, room, and board, food, travel, textbooks, weather appropriate clothing and entertainment expenses as well.</p>
        <p>Here is a range of costs that have been considered while estimating the living expenses</p>
        <ul>
            <li>Books and study material costs around $500 to $1000 per year</li>
            <li>Travel costs within the US will be from $300 to $700</li>
            <li>Accommodation will cost between $5000 and $7500 per year</li>
            <li>For living off campus, the cost of renting an apartment will be between $300 and $600 a month but could be higher depending on the location of the school</li>
            <li>Meals should cost around $2500 a year if you do not eat out too many times</li>
            <li>Shopping for clothes will cost more than $500 annually if you are in a cold country</li>
            <li>Personal and variable expenses will be around $2000 per year</li>
        </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      Types of Universities
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <h5>Public Universities:</h5>
      <p>State Funded Institutions.</p>
      <ul>
        <li>Not for profit and financially supported by Public Taxes.</li>
        <li>University offers Bachelors , Masters and Doctorate courses.</li>
        <li>Usually large in Size.</li>
        <li>All levels of degrees with different fields of study are offered.</li>
      </ul>
      <p>Universities:- Arizona State University, University of Arizona, University of Maryland Baltimore County,George Mason University, Colorado State University, Oregon State University, University of SouthFlorida, West Virginia University, Virginia Common Wealth, University of Nebraska – Lincoln,Washington State University, University of Cincinnati, Auburn University, Montana State University, SanJose State University, Illinois State University, San Francisco State University, Tennessee Tech University,University of Idaho, Wright State University, California State University East Bay and Fresno and manymore.</p>
      <br />
      <h5>Private Universities</h5>
      <p>These Universities are not State funded.</p>  

      <ul>
        <li>Tuition fees are higher.</li>
        <li>Even all ranked Ivy Leagues are Private.</li>
        <li>The tuition fees are almost the same for Domestic as well as International students</li>
      </ul>

      <p>Universities:- D’Amore -McKim School of Business, American University, Long Island University-Brooklyn, Long Island University -C.W. Post, University of New Haven, University of St. Thomas, University of Bridgeport, California Lutheran University, Roosevelt University, Valparaiso University, University of Tampa, Oklahoma City University, Upper Iowa University, City University of Seattle, Southern New Hampshire, University of Pacific, Hult International Business School & many more.</p>
      <br />
      <h5>Ivy League</h5>
      <p>Oldest & most famous in US.</p>
      <ul>
        <li>Located mainly in the Northeastern part of the US.</li>
        <li>Known for their Business School also have many graduate & professional schools.</li>
        <li>Tuition fees at these private schools is among the highest in the</li>
        <li>Admission is highly competitive.</li>
        <li>Institutions :‐ University of Pennsylvania, Harvard University, Yale University, Princeton University, Brown University, Dartmouth College , Cornell University & Columbia University</li>
      </ul>
      <br />
      <h5>Community Colleges</h5>
      <p>Two‐year community colleges usually offer the associate’sdegree such as an Associate of Arts (AA) or Associate of Science (AS)</p>
      <ul>
        <li>Community colleges often have open admissions policies with lowtuition fees.</li>
        <li>These institutions offer academic, technical and vocational degrees. Provide Certificates, Diplomas & Associate Degrees.</li>
        <li>Some community colleges have automatic enrollment agreements with a local 4 year college or University where the community college provides the first two years of study and the University provides the remaining two years of study. These programs are often called 2 + 2 programs.</li>
      </ul>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Intakes
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h5>US universities have 3 intakes</h5>
       <p>1. Spring: January/February (Minor)</p>
       <p>2. Fall: August/September (Major)</p>
       <p>3. Summer: May/June (Rare)</p>

       <p>Major intake is the Fall intake, but most of the universities are open for Spring and few of
them for summer & winter intake too. However all subjects commence in Fall and only some in
Winter and Summer intake</p>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Types of Degrees offered
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>1. Associate Degree : Academic degree of 2 years is awarded by Community Completion of associate degree can lead to transfer to the remaining 2 years bachelors degree in high ranked university having an articulated agreement with the community college</p>
        <p>2. Bachelors Degree : It is Commonly known as Undergraduate Degree and the duration is of 4 For Ex : Bachelor of Arts (B. A.) or Bachelor of Science(B.S.)</p>
        <p>3. Masters Degree : The duration generally for all the masters course is of 2 Common examples of master degree are Masters of Arts (MA), Masters of Science (MS), Masters of Engineering (MEng), Masters of Business Administration. But for courses like Masters in Management, MEng. Degrees, LLM etc. the duration may be of one year</p>
        <p>4. Doctorate : Commonly known as Doctor of Philosophy (Ph.D.). Duration may range from 3-5 Ph.D. students need to submit a research proposal as well as have to appear Standardized Test i.e. GRE/GMAT and TOEFL/IELTS for admission.</p>
        <h6>In the United States, Students with Bachelor’s degree may apply directly into doctoral program without first earning master degree in some majors.</h6>
        <img src="/img/usimg1.png" className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Available Courses (Programs)- Undergraduate
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Bachelor of Arts</h6>
        <p>majors in Anthropology/History/Economics/Biology/Mathematics/Chemistry/C.S./Psychology/Mathematics</p>
        <br />
        <h6>Bachelor of Science</h6>
        <p>major in Mechanical/Electrical/Computer Engg/Mathematics/C.S./Physics/Economics/Business Administration/Finance</p>
        <br />
        <h6>Other Degrees –</h6>
        <p>Bachelor in Fine Arts (Performing Arts/Drama & Theatre)</p>
        <p>Bachelor of Business Administration</p>
        <p>Bachelor of Architecture</p>
        <p>**A “minor” is a secondary area of specialization. It’s common for students in the USA to earn a degree with one major and one minor. Compared to a major, a minor is a lot less intensive, and only requires 4–6 courses to complete.</p>
        <p>* Bachelor degree in USA having 4 years of duration </p>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwelwe">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
      Available Courses (Programs)- Postgraduate
      </button>
    </h2>
    <div id="collapseTwelwe" className="accordion-collapse collapse" aria-labelledby="headingTwelwe" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ol>
        <li> Master of Arts (M.A.)– Any Arts subjects like Psychology/Social Work/History/Political Science</li>
        <li>Master of Science (M.S.)– Research and Course based, MS degree provided to any Science/Engineering/Business stream subjects</li>
        <li>B.A. Specializations in Management subjects like Finance/Logistics & Operations/Marketing etc</li>
        <li>Master of Fine Arts (M.F.A.)– Film Studies/Drama/Performing Arts</li>
        <li>Master of Architecture (M.Arch)– 2 years of duration for having Arch degree/ 3 years of duration for having any Science/Engineering bachelor 4-years degree </li>
        <li>. Professional Masters (M.P.S.)– Master in Data Science, Computer Science or MBA, duration of programs less than 2 years</li>
        <li> Other Degrees like Master of Public Health (MPH),MHA (Master of Health Administration), Master of Physician Assistant (MPA)</li>
      </ol>
      </div>
    </div>
  </div>

    </div>
    </>
  )
}
