import React from 'react'

export default function Genralinfo() {
  return (
    <>
    <div className="accordion" id="accordionExample">
        
    <div className="accordion-item">
    <h2 className="accordion-header" id="headingFifteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
      GENERAL ACADEMIC REQUIREMENTS
      </button>
    </h2>
    <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p><strong>Foundation programs</strong>– Minimum 40% in Class XII</p>
       <p><strong>International Year 1</strong>– Minimum 50% in Class XII</p>
       <p><strong>Bachelors</strong>– Minimum 65% in Class XII</p>
       <p><strong>Pre-Masters</strong>– Minimum 45% in Bachelors</p>
       <p><strong>Masters</strong>– Minimum 55% in Bachelors or qualifying program The minimum percentage may vary as per the requirement of different universities and programs. IELTS is not mandatory at certain UK Universities. UKVI IELTS required for Foundation, IY1 and Pre-Masters for Non-ICAS programs.</p>
       <p><strong>Note</strong>– ICAS is provided by certain pathway providers. It means Integrated CAS (combined CAS for Pathway(Foundation/International Year 1/Pre-Masters) and the progression degree). Non-ICAS means the student will have to reapply for CAS for progression degree after completing the pathway program</p>
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingSixteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
      FEES & SCHOLARSHIPS
      </button>
    </h2>
    <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p><strong>Application Fees For Bachelors</strong>– UCAS application (Sep/Oct intake only) – 1 University/2 Choices – £22.50; Multiple choice (2-5 Choices/2-5 universities) – £27 Applications processed online or agent portal – No application fee.</p>
       <p><strong>For Masters</strong>– Program specific, few Masters’ program– Universities of Newcastle, Bath, Durham, Edinburgh, Surrey. All Courses – University of Warwick, Manchester, Nottingham, Cambridge, Oxford, King’s College London, Imperial College London, University College London, London School of Economics For Pre-Masters and Undergraduate Foundation programs – SOAS, University of London</p>
       <p><strong>Tuition Fees</strong>– Approx. 12,000 GBP onwards, depends upon University and course selection.</p>
       <p><strong>Scholarships</strong>– International students are eligible for scholarships as per universities’ policies. Some may require applications for scholarships.</p>
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeventeen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
      DOCUMENT CHECKLIST FOR APPLICATION
      </button>
    </h2>
    <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h5>Academic documents –</h5>
       <ul>
        <li>Degree/Certificates and Mark sheets of class X, XII, Bachelors, Masters (as per the requirement of the program)</li>
        <li>At least, two academic reference letters</li>
        <li>If one has work experience then two professional LORs from most recent employer/manager who can comment on one’s professional abilities</li>
        <li>Statement of Purpose (SOP)</li>
        <li>Essay (if required)</li>
        <li>Resume</li>
        <li>Score reports of GMAT / IELTS / TOEFL/PTE (if applicable)</li>
        <li>Portfolio (for Art and Design & Architecture programs)</li>
        <li>Others (certificates/achievements at the state and national level and extracurricular activities)</li>
       </ul>
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingEighteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
      DOCUMENTS
      </button>
    </h2>
    <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h5>SOP</h5>
       <p>A Statement of Purpose is always written in the first person and describes the reason for applying to a particular program, university and country and its importance for one’s future career prospects. It should highlight one’s suitability for the chosen program.</p>

       <h5>Essay</h5>
       <p>For certain programs require students may be required to write an essay. Common topics include career aspirations, strengths and weaknesses, skills, experiences, and reasons for considering a particular school.</p>

       <h5>GMAT</h5>
       <p>Certain universities require GMAT scores for the MBA program. The average GMAT accepted score across universities is 600. It may vary as per the university requirements.</p>

       <h5>GRE</h5>
       <p>The Graduate Record Examination may be required by certain graduate schools to pursue MA or MS. Many business schools also accept GRE scores for MBA.</p>
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingNineteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
      MONEY MATTERS
      </button>
    </h2>
    <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h5>Cost of living (As per the UKVI guidelines)</h5>
       <p><strong>London – </strong>£1,023 per month/£9,207 (for 9 months) On an average, the monthly expenditure ranges between £600 to £1000 or above per month to live in or around London. The costs vary depending on one’s lifestyle and place of residence.</p>

       <h5>Post Study Work Permit</h5>
       <p>The UK government has announced the reinstatement of the two-year post-study work visa (Graduate Route) for international students. The new immigration rules will benefit those international students who are planning to pursue their higher studies in the UK. It is basically a Job Search Visa.</p>
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwenty">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
      FINANCES
      </button>
    </h2>
    <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h6>Proof of Funds</h6>
       <p>The funds to be shown to the university and for visa purposes should be maintained for 28 days either in the student’s name or the parents’ name.</p>

       <h6>Acceptable funds</h6>
       <p>Savings in Bank account </p>
       <p>Fixed Deposits (At least 5-6 months old)</p>
       <p>*No major transactions during these 28 days</p>

       <h6>Education Loan</h6>
       <p>Some of the universities like Anglia Ruskin University now requires ITRs and Bank Manager contact details to ascertain the genuineness of the funds.</p>

       <h6>Funds to be shown</h6>
       <p>Tuition fees - 1 year (Outstanding amount minus paid deposit)</p>
       <p>Living expenses - 1 year (London - £12,006/ Outside London - £9,207)</p>
      </div>
    </div>
  </div>

    </div>
    </>
  )
}
