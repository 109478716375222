import React from 'react'
import img1 from '../assets/newfolder/canimg1.png'

export default function Module1() {
  return (
    <>
    <div className="accordion" id="accordionExample">
  {/* Tab 1 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Map of Canada
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse " data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <img src={img1} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  {/* Tab 2 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Provinces and Territories of Canada
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <table className="table table-striped">
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Ontario</td>
      <td>Toronto</td>
      <td>Toronto</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Quebec</td>
      <td>Quebec City</td>
      <td>Montreal</td>
    </tr>
    
    <tr>
      <th scope="row">3</th>
      <td >Nova Scotia</td>
      <td>Halifax</td>
      <td>Halifax</td>
    </tr>

    <tr>
      <th scope="row">4</th>
      <td >New Brunswick</td>
      <td>Fredericton</td>
      <td>Saint John</td>
    </tr>

    <tr>
      <th scope="row">5</th>
      <td >Manitoba</td>
      <td>Winnipeg</td>
      <td>Winnipeg</td>
    </tr>

    <tr>
      <th scope="row">6</th>
      <td >British Columbia</td>
      <td>Victoria</td>
      <td>Vancouver</td>
    </tr>

    <tr>
      <th scope="row">7</th>
      <td >Prince Edward Island</td>
      <td>Charlottetown</td>
      <td>Charlottetown</td>
    </tr>

    <tr>
      <th scope="row">8</th>
      <td >Saskatchewan</td>
      <td>Regina</td>
      <td>Saskatoon</td>
    </tr>

    <tr>
      <th scope="row">9</th>
      <td >Alberta</td>
      <td>Edmonton</td>
      <td>Calgary</td>
    </tr>

    <tr>
      <th scope="row">10</th>
      <td >Newfoundland and Labrador</td>
      <td>St. John’s</td>
      <td>St. John’s</td>
    </tr>

    <tr>
      <th scope="row">Sr No.</th>
      <td >Territory</td>
      <td>Capital</td>
      <td>Largest City</td>
    </tr>

    <tr>
      <th scope="row">1</th>
      <td>Northwest Territories</td>
      <td>Yellowknife</td>
      <td>Yellowknife</td>
    </tr>

    <tr>
      <th scope="row">2</th>
      <td>Yukon</td>
      <td>Whitehorse</td>
      <td>Whitehorse</td>
    </tr>

    <tr>
      <th scope="row">3</th>
      <td>Nunavut</td>
      <td>Iqaluit</td>
      <td>Iqaluit</td>
    </tr>

  </tbody>
</table>
      </div>
    </div>
  </div>

  {/* Tab 3 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Fast Facts
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ol>
            <li>Capital  of Canada-OTTAWA</li>
            <li>Number of Provinces in Canada- 10</li>
            <li>Number of Territories in Canada-03</li>
            <li>Popular cities in Canada-Vancouver, Victoria,Toronto, Calgary, Halifax, Montreal and Winnipeg.</li>
            <li>Official Languages:Canada’s two official languages are English and French, but more than 200 languages are spoken.</li>
        </ol>
      </div>
    </div>
  </div>

  {/* Tab 4 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Canada’s Regions-Discover Canada
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>Canada is the second largest country on earth—10 million square kilometres. Three oceans line Canada’s frontiers: the Pacific Ocean in the west, the Atlantic Ocean in the east, and the Arctic Ocean to the north. Along the southern edge of Canada lies the Canada-United States boundary. Both Canada and the U.S. are committed to a safe, secure and efficient frontier</p>
       <h6>The Regions of Canada</h6>
       <p>Canada includes many different geographical areas and five distinct regions.</p>
       <ul>
        <li>The Atlantic Provinces</li>
        <li>Central Canada</li>
        <li>The Prairie Provinces</li>
        <li>The West Coast</li>
        <li>The Northern Territories</li>
       </ul>

       <table className="table table-striped">
       <thead>
    <tr>
      <th scope="col">Region</th>
      <th scope="col">Province/Territory</th>
      <th scope="col">Capital City</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    
    <tr>
      <th scope="row">Atlantic region</th>
      <td>
        <ul>
            <li>Newfoundland and Labrador</li>
            <li>Prince Edward Island</li>
            <li>Nova Scotia</li>
            <li>New Brunswick</li>
        </ul>
      </td>
      <td>
        <ul>
            <li>St. John’s</li>
            <li>Charlottetown</li>
            <li>Halifax</li>
            <li>Fredericton</li>
        </ul>
      </td>

      <td><p>Atlantic Canada’s coasts and natural resources, including fishing, farming, forestry and mining, have made these provinces an important part of Canada’s history and development. The Atlantic Ocean brings cool winters and cool humid summers.</p></td>
    </tr>

    <tr>
      <th scope="row">North</th>
      <td>
        <ul>
            <li>Nunavut</li>
            <li>Northwest Territories</li>
            <li>Yukon Territory</li>
        </ul>
      </td>
      <td>
        <ul>
            <li>Iqaluit</li>
            <li>Yellowknife</li>
            <li>Whitehorse</li>
        </ul>
      </td>

      <td><p>The Northwest Territories, Nunavut and Yukon contain one-third of Canada’s land mass but have a population of only 100,000. There are gold, lead, copper, diamond and zinc mines. Oil and gas deposits are being developed. The North is often referred to as the “Land of the Midnight Sun” because at the height of summer, daylight can last up to 24 hours. In winter, the sun disappears and darkness sets in for three months. The Northern territories have long cold winters and short cool summers. Much of the North is made up of tundra, the vast rocky Arctic plain.</p></td>
    </tr>

    <tr>
      <th scope="row">Central Canada</th>
      <td>
        <ul>
            <li>Quebec</li>
            <li>Ontario</li>
        </ul>
      </td>
      <td>
        <ul>
            <li>Québec City</li>
            <li>Toronto</li>
        </ul>
      </td>

      <td><p>More than half the people in Canada live in cities and towns near the Great Lakes and the St. Lawrence River in southern Quebec and Ontario, known as Central Canada and the industrial and manufacturing heartland. Southern Ontario and Quebec have cold winters and warm humid summers. Together, Ontario and Quebec produce more than three-quarters of all Canadian manufactured goods.</p></td>
    </tr>

    <tr>
      <th scope="row">Prairie Provinces</th>
      <td>
        <ul>
            <li>Manitoba</li>
            <li>Saskatchewan</li>
            <li>Alberta</li>
        </ul>
      </td>
      <td>
        <ul>
            <li>Winnipeg</li>
            <li>Regina</li>
            <li>Edmonton</li>
        </ul>
      </td>

      <td><p>Manitoba, Saskatchewan and Alberta are the Prairie Provinces, rich in energy resources and some of the most fertile farmland in the world. The region is mostly dry, with cold winters and hot summers.</p></td>
    </tr>

    <tr>
      <th scope="row">West Coast</th>
      <td>
        <ul>
            <li>British Columbia</li>
        </ul>
      </td>
      <td>
        <ul>
            <li>Victoria</li>
        </ul>
      </td>

      <td><p>British Columbia is known for its majestic mountains and as Canada’s Pacific gateway. The Port of Vancouver, Canada’s largest and busiest, handles billions of dollars in goods traded around the world. Warm airstreams from the Pacific Ocean give the B.C. coast a temperate climate.</p></td>
    </tr>
    

  </tbody>
</table>


      </div>
    </div>
  </div>

  {/* Tab 5 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Why Study in CANADA
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Qualifications valued around the World.</li>
            <li>Affordable Education.</li>
            <li>Multicultural Society.</li>
            <li>Healthy and Safe Communities.</li>
            <li>Possibility of paid Internships while studying.</li>
            <li>Post Study Work Visa and good Job opportunities after completion of studies.</li>
            <li>Possibility of Permanent Residency.</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Tab 6 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Education System in CANADA
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Education in Canada is generally funded, provided and managed by Provincial Government of Canada</p>
        <p>There are 2 types of institutions in Canada:</p>
        <ul>
            <li>Universities</li>
            <li>Community Colleges</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Tab 7 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      About Universities
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>There are 98+ Universities in Canada.</li>
            <li>They offers Bachelors, Masters and PhD programs.</li>
            <li>Masters and PhD Programs are available at Universities  only. Some Canadian Universities offers Diploma programs and Associate Degrees</li>
        </ul>
      </div>
    </div>
  </div>

  {/* Tab 8 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      About Community Colleges
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>There are 150 Community Colleges across Canada.</li>
            <li>Offer UG Diplomas, Bachelors Degrees,  University Transfer programs and PG Diplomas.</li>
        </ul>

        <h6>Others</h6>
        <h6>CEGEPs</h6>
        <p>CEGEP, which stands for Collège D’Enseignement Général et Professionnel, and translates to “General and Vocational College” is a type of post-secondary institution that is only present in the province of Quebec. A CEGEP offers pre-university and technical programs which lead to “Diploma of College Studies”, equivalent to grade 12 and the first year of university studies. For students in Quebec, completion of CEGEP is a prerequisite for admission into Year two (of four) in Quebec universities.Attestation of College Studies (AEC)</p>
        <h6>Vocational Schools</h6>
        <p>Vocational schools (also called career, technical, and trade schools) offer occupation-specific programs geared towards helping students go directly from school into their chosen careers. These programs can range from a few months to one-to-two years, and often include a work component. Examples of vocational training programs include medical assisting, automotive repair, flight training, culinary arts, and computer support. Graduates from a vocational school/career program earn a completion certificate from their school. Though often referred to as “career diploma” by vocational schools, it should be noted that these certificates are not equivalent to college diplomas.</p>

      </div>
    </div>
  </div>

  {/* Tab 9 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Difference between Colleges and Universities
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <img src="/img/resource-canada1.png" className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  {/* Tab 10 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Difference between University and College in Canada (contd..)
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>In Canada the most important difference between a college and university education are the qualification you will hold after graduating and the types of jobs for which you will qualify. Positions offered by the government and large private companies such as for research will often require a university degree.</p>
        <p>College Diplomas and Post Graduate Certifications can place you in a position to compete for very good jobs in specialized fields. College graduates often have the upper hand over university graduates when applying to positions that require applied training.</p>
      </div>
    </div>
  </div>

  {/* Tab 11 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Categorization of Degrees
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>DOCTORAL OR PHD</h6>
        <ul>
            <li>A minimum of 2‐5 years of full‐time study for doctoral programs is required after masters.</li>
        </ul>

        <h6>MASTERS</h6>
        <ul>
            <li>Masters degrees comprise of at least 2 years of full‐time studies including a thesis (a long essay based on original research), practicum (Co‐op) or research paper.</li>
        </ul>
        
        <h6>POST DEGREE DIPLOMA / POST GRADUATE CERTIFICATE/POST BACCALAUREATE DEGREE</h6>
        <ul>
            <li>Generally are of 1 year duration and exceptionally of 2 years as well.</li>
            <li>Some colleges offers bundled programs as well. For ex: Seneca College,St Lawrence College,Conestoga College etc.</li>
        </ul>
        <h6>UNDERGRADUATE</h6>
        <ul>
            <li>Bachelor’s degree consists of 4 years of full‐time study.Some universities offer 3 years degrees as well</li>
            <li>Associate Degree: 2 years degree ,offered in BC and Alberta only</li>
        </ul>

        <h6>UG DIPLOMA (Post Secondary Diploma)</h6>
        <li>A UG Diploma generally comprises of two or three years of full time study.</li>
        <li>2 year program is a Diploma and 3 year program is an Advance Diploma.</li>

      </div>
    </div>
  </div>

  {/* Tab 12 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
      Duration of programs and credits at the Undergraduate level:
      </button>
    </h2>
    <div id="collapseTwelve" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        
      <table className="table table-striped">
  <thead>
    <tr>
      <th scope="col">Degree</th>
      <th scope="col">Duration</th>
      <th scope="col">Credits</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">Bachelors</th>
      <td>4 years or 3 years</td>
      <td>120 credits or 123 credits for 4 years  and 90 credits for 3 years</td>
    </tr>
    <tr>
      <th scope="row">Associate Degree</th>
      <td>2 years</td>
      <td>60 credits</td>
    </tr>
    <tr>
      <th scope="row">Diploma</th>
      <td>2 years</td>
      <td>60 credits</td>
    </tr>
    <tr>
      <th scope="row">Advanced Diploma</th>
      <td>3 years</td>
      <td>90 credits</td>
    </tr>
    <tr>
      <th scope="row">Certificate</th>
      <td>1 years or 8 months</td>
      <td>30 credits</td>
    </tr>
  </tbody>
</table>

      </div>
    </div>
  </div>

  {/* Tab 13 */}
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseThirteen">
      Pathway Programs
      </button>
    </h2>
    <div id="collapseThirteen" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Students who wish to study in Canada may first attend a prerequisite or preparatory program to meet the entry requirements for their desired degree or diploma program. This is called a pathway program. Pathway programs bridge the gap between the qualifications from a student’s home country and the level needed to enter into a post-secondary institution in Canada.</p>
        <h6>English Language Pathway Programs:</h6>
        <p>It is offered to those who are not meeting the entrance requirements of language proficiency</p>
        <h6>Academic Pathway Programs</h6>
        <p>Academic pathway programs focus on academic subjects, offering students foundational courses in math, the sciences, business, the arts, etc., to improve their subject knowledge and to meet the prerequisite course requirements for admission into undergraduate and graduate programs.</p>
        <img src="/img/resource-canada2.png" className='img-fuild' alt="" />
      </div>
    </div>
  </div>
</div>

    </>
  )
}
