import React from 'react'

export default function Visainfo() {
  return (
    <>
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwentyone">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyone" aria-expanded="false" aria-controls="collapseTwentyone">
      VISA APPLICATION
      </button>
    </h2>
    <div id="collapseTwentyone" className="accordion-collapse collapse" aria-labelledby="headingTwentyone" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Student Visa Application Fees – GBP 363</p>
        <p>The High Commission may take 3 weeks (working days) for visa decision.</p>

       <h6>Document Checklist:</h6>
       <ul>
        <li>Valid Passport</li>
        <li>Any previous passport to show previous travel history</li>
        <li>CAS</li>
        <li>Tuberculosis Certificate</li>
        <li>Proof of funds – Education Loan/Bank statements/FDs, Balance Confirmation certificates</li>
        <li>Affidavit of students</li>
        <li>Affidavit of Support from parents (in case of under 18 student or if the funds are in parents’ name )</li>
        <li>Birth Certificate (in case of under 18 age or if the funds are in parents’ name)</li>
        <li>ID proofs of student and sponsors (PAN card, Aadhar Card)</li>
        <li>SOP</li>
       </ul>
       <p>International Health Surcharge to be paid at the time of Visa Application process. IHS is mandatory for international students. It is a part of the student visa issued to them. The health surcharge is £470 per annum and student requires to pay for entire study period (plus 6 months) in one go. *Credit Card/ International Debit card is required to pay the IHS and Visa Application Fee</p>

      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwentytwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentytwo" aria-expanded="false" aria-controls="collapseTwentytwo">
      TIPS FOR STUDENTS
      </button>
    </h2>
    <div id="collapseTwentytwo" className="accordion-collapse collapse" aria-labelledby="headingTwentytwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Orientation</h6>
        <p>Orientation week is important for international students. The freshers are introduced to the university and its services and get enrolled for the classes. The University Guide Book explains each part of the admission process. </p>

        <h6>Open Bank Account & Register with local Doctor</h6>
        <p>During the induction and registration period international students are provided the letter from their respective institute to open a bank account and get help to be registered with a local doctor.</p>

       <h6>National Insurance Number</h6>
       <p>National Insurance number (NINo) is a unique personal number which is used to record one’s National Insurance contributions. Students do not need to have a NINo before starting work, but must obtain one when they get a job. <a href="https://www.ukcisa.org.uk/Information–Advice/Working/Employers-income-tax-National-Insurance">https://www.ukcisa.org.uk/Information–Advice/Working/Employers-income-tax-National-Insurance</a></p>

       <h6>Part-time work</h6>
       <p>Full-time students can work for 20 hours per week at maximum, whether one gets paid or not for the job. Students pursuing below degree level program or Tier 4(Child) visa are allowed to work for only 10 hours per week. <a href="https://www.gov.uk/national-minimum-wage-rates">https://www.gov.uk/national-minimum-wage-rates</a></p>
      
      </div>
    </div>
  </div>

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwentythree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentythree" aria-expanded="false" aria-controls="collapseTwentythree">
      Dependent Visa
      </button>
    </h2>
    <div id="collapseTwentythree" className="accordion-collapse collapse" aria-labelledby="headingTwentythree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Visa Fees –</h6>
        <p>GBP 363 per dependent</p>

        <h6>Living Expenses</h6>
        <ul>
            <li><strong>London – £845 per month (£7605 for 9 months)</strong></li>
            <li><strong>Outside London – £680 per month (£6120 for 9 months)</strong></li>
        </ul>

        <h6>Evidence of Relationship</h6>
        <ul>
            <li><strong>A marriage or civil partnership certificate for partner</strong></li>
            <li><strong>A birth certificate for your child</strong></li>
        </ul>
        <p>If the child is 16 or 17 years of age, then evidence is required to prove that he is not living independently. At least 2 documents required to prove that the address is the same. These can be a bank statement, credit card bills, driving license, NHS registration document or official letter from their university or college</p>

       <h6>Each dependent needs to pay –</h6>
       <p>Health Surcharge £19.20 to have their biometric information (fingerprints and a photo) taken</p>

       <h6>Work Rights</h6>
       <p>The dependent of the student can work full time, except as a professional sportsperson/sports coach/practice Medicine profession.</p>

       <ul>
        <li>Jobs to be restrained from as a student on Student Route visa.</li>
        <li>Be a self-employed or working freelance</li>
        <li>Initiate a business activity</li>
        <li>Full-time permanent job</li>
        <li>Professional sportsperson including sport coach</li>
        <li>Work as an entertainer</li>
        <li>Work as a dentist or a doctor in training, except you’re enrolled in a foundation program.</li>
       </ul>

       <h6>Activities</h6>
       <p>Sports, Music, drama, science and literary societies in colleges offer opportunities for outdoor education and other leisure activities. Visits to theaters, concerts, and places relevant to the courses of study such as art galleries and museums, religious centers or historical sites, scientific companies and projects are all part of college life. Students have full access to a wide range of clubs and societies at the university. Social events are also organized by the universities. </p>

       <h6>Indians in UK</h6>
       <p>Indians are the UK’s largest ethnic minority group. The majority of all Indian immigrants reside in three cities: London, Manchester, and Birmingham. The rest of the Indian immigrants live in major metropolitan areas like Cardiff, Glasgow, Edinburgh, and Belfast.</p>

       <h6>Accommodation</h6>
       <p>Short-term accommodation -Hostels and hotels. Rental – You can rent or ‘lease’ a property by yourself or with friends. On-Campus – Living can be a great option to minimize travel. Homestay – This option allows you to live with a family at their home.</p>
      
      </div>
    </div>
  </div>

    </div>
    </>
  )
}
