import React from 'react'
import Mapuk from './Mapuk'
import Couseuk from './Couseuk'
import Studentapplicationuk from './Studentapplicationuk'
import Genralinfo from './Genralinfo'
import Visainfo from './Visainfouk'
import ukbg from '../assets/img/europebg.jpg'
import ukflag from '../assets/flags/UK.png'

export default function Ukpage() {
  return (
    <>
          {/* hero section start */}
  <div id='#customcontainer' className="customcontainer mt-4 pt-3">
 <div className="bg-image d-flex justify-content-center mt-5 align-items-center" style={{
  backgroundImage: `url(${ukbg})`,
  height: '500px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  backgroundBlendMode: 'overlay'
}}>
    <div className="row landingformlayout w-100 mx-0 px-5 mt-5">
    <div className="col-md-8 ieltslandingcontent">
     <h1 className="bannerHeading text-light">Why Study In UK?</h1>
     <ul className="bannerPoints text-dark">
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> International Recognised Universities and Qualifications</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Quality Education</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Strong Research Infrastructure </li>
		    <li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Shorter Duration of Courses</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Degree programs available in every field</li>
			<li className='my-3 ullist text-light'><i className="fa fa-check-circle"></i> Scholarship and Financial Support </li>
		</ul>
            <img src={ukflag} className='mt-2 mx-4' style={{width:'80px'}}/>
     </div>

     <div className="ieltslandingcontentmobile" style={{display:'none'}}>
     <h1 className="bannerHeading text-light">Why to Study in UK ?</h1>
</div>



    </div>
 
</div>

 </div>
{/* hero section ended */}

<div className="container">
    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>United </span>Kingdom</h4>
    </div>
    <Mapuk/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Courses &</span> Intakes</h4>
    </div>
    <Couseuk/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Student Application</span> Process</h4>
    </div>
    <Studentapplicationuk/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>General</span> Information</h4>
    </div>
    <Genralinfo/>

    <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Visa</span> Information</h4>
    </div>
    <Visainfo/>

    </div>



    </>
  )
}
