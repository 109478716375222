import React from 'react'
import ausimg from '../assets/img/ausimg.png'

export default function Ausintro() {
  return (
    <>
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      About the country
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>Australia is constituted of six states and two territories</p>
        <p>It is the largest country in Oceania and the world’s sixth-largest country by total area.</p>
        <p><strong>States:–</strong></p>
     <ul>
        <li>New South Wales</li>
        <li>Queensland</li>
        <li>South Australia</li>
        <li>Tasmania</li>
        <li>Victoria</li>
        <li>Western Australia</li>
        <li>Australian Capital Territory</li>
        <li>Northern Territory</li>
       
     </ul>
    
    <img src="/img/usmap.png" className='img-fluid' alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      General Information
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <img src={ausimg} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Types of </span>Educational Institutes</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Universities
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>There are 43 universities in Australia (38 Public universities, two international universities, and three private university).</p>
        <p>The top universities in Australia (in no particular order) are –</p>
        <ul>
            <li>The Australian National University</li>
            <li>University of Adelaide</li>
            <li>The University of New South Wales</li>
            <li>The University of Melbourne</li>
            <li>The University of Western Australia</li>
            <li>University of Queensland</li>
            <li>University of Sydney</li>
            <li>Monash University</li>
        </ul>
        <p>International Universities:– Carnegie Mellon University and University College London</p>
        <p>Private Universities:- Bond University, University of Notre Dame and Torrens University</p>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Private Colleges
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
			<p><i className="fa fa-check-circle"></i> Independent Colleges offer Bachelor’s & Master’s Degrees e.g., International College of Management, Sydney.</p>
			<p><i className="fa fa-check-circle"></i> Some colleges are affiliated with universities, e.g., Australian Technical and Management College affiliated with Federation University. These colleges offer Information Technology and Business programs.</p>
      </div>
    </div>
  </div>
  
  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Courses / </span>Intakes</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Popular courses
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       
       <ul>
        <li>Business Management & Accounting</li>
        <li>Architecture & Urban Planning</li>
        <li>Information Technology & Computer Science</li>
        <li>Engineering and Project Management</li>
        <li>Biotechnology & Biomedical Sciences</li>
        <li>Media & Communication</li>
        <li>Nursing & Health Care</li>
        <li>Education & Teaching</li>
        <li>Hotel Management</li>
       </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Intakes
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Major intakes in Australia are February and July</li>
            <li>Some programs related to Business, Information Technology and Engineering are offered in October/November by Private Colleges and some of the major universities, e.g. Deakin University and Griffith University.</li>
        </ul>
      </div>
    </div>
  </div>
    
  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>General Academic </span>Requirements</h4>
    </div>


  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      Bachelors & Masters
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>Class XII Minimum 60% from CBSE/ICSE; Other Boards – 65% and above</li>
        <li>Bachelors – Minimum 50% from Section 1 universities;</li>
        <li>55% and above from Section 2 universities; Section 3 universities – 60% and above</li>
      </ul>

      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>IELTS</span></h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      English language Requirements
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <table className="table table-bordered">
            <thead className="table-light">
                <tr>
                    <th scope="col"></th>
                    <th scope="col">IELTS</th>
                    <th scope="col">TOEFL iBT</th>
                    <th scope="col">PTE</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row">Bachelors</th>
                    <td>Overall 6.0/6.5 with no band less than 6.0</td>
                    <td>74 and above</td>
                    <td>50 and above</td>
                </tr>
                <tr>
                    <th scope="row">Masters</th>
                    <td>Overall 6.5 with no band less than 6.0</td>
                    <td>79 and above</td>
                    <td>58 and above</td>
                </tr>
            </tbody>
        </table>
        <p>Requirements may change as per University or course individual requirements</p>
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Application</span> Process</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Student Application Process
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <h5 className="mb-4">Student Application Process</h5>
        <ol>
            <li><strong>Apply (Online/Offline)</strong></li>
            <li><strong>Conditional/Unconditional Offer</strong><br/>
                Processing time – 1 to 4 weeks. If conditional offer is received, fulfil the conditions to avail unconditional offer.
            </li>
            <li><strong>GTE Assessment</strong><br/>
                Financial assessment by the university to ascertain that the student is Genuine Temporary Entrant.
            </li>
            <li><strong>Tuition fees Payment & OSHC</strong><br/>
                After approval of funds pay tuition fees for 1st semester or as mentioned in the offer letter. Purchase OSHC (Overseas Student Health Cover). OSHC can be availed through the university also.
            </li>
            <li><strong>eCOE</strong><br/>
                After the acceptance of offer letter and providing the evidence of payment of tuition fees & OSHC, confirmation of the Enrolment (COE) is received from the university.
            </li>
            <li><strong>Medical Examination</strong><br/>
                By one of the doctors approved by the Immigration Australia.
            </li>
            <li><strong>Visa Lodgement</strong><br/>
                Lodged online by creating immi account on immigration website.
            </li>
        </ol>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Document Checklist for Application
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul className="list-group">
        <li className="list-group-item">Passport</li>
        <li className="list-group-item">Academic Documents - Marksheets and Certificates of class X, XII, and the Bachelor, Master, etc. (whichever is applicable)</li>
        <li className="list-group-item">English Language Proficiency Score report – IELTS/PTE/TOEFL</li>
        <li className="list-group-item">Resume</li>
        <li className="list-group-item">Statement of Purpose (SOP) for Masters and especially for MBA. (Some universities may ask for certain Bachelor programs also)</li>
        <li className="list-group-item">Portfolio (for Art and Design courses & Architecture programs)</li>
        <li className="list-group-item">Two academic reference letters from professors (if applicable)</li>
        <li className="list-group-item">If one has work experience then two letters of recommendation from the employer/manager who can comment on one’s professional abilities</li>
        <li className="list-group-item">Work Experience (if applicable)</li>
        <li className="list-group-item">Others (certificates / achievements at the state and national level and extracurricular activities)</li>
    </ul>
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Fees/</span> Scholarship</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Fees/Scholarships
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Application Fees</h6>
        <p>Some universities may have application fees in the range of AU$100-150.</p>
        <br />
        <h6>Tuition Fees</h6>
        <p>Bachelors – AU$ 23,000 to AU$54,000 approx. (depends on University/Course)</p>
        <p>Masters – AU$ 23,000 to AU$55,000 approx. (depends on University/Course)</p>
        <br />
        <h6>Scholarships</h6>
        <p>Scholarships available for deserving students. These depend on academic score and Universities’ scholarship policies.</p>
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Money</span> Matters</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingTwelwe">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
      Cost of Living
      </button>
    </h2>
    <div id="collapseTwelwe" className="accordion-collapse collapse" aria-labelledby="headingTwelwe" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p><strong>(As per the Australia GTE requirement)</strong></p>
        <p>Student – AU$ 21,040</p>
        <p>Spouse – AU$ 7,362</p>
        <p>Child – AU$3,152</p>
    <p>School going child – AU$8,296 (School Cost)On an average, the monthly expenditure (of student only) ranges between AU$2,400 to AU$2,800 or above per month. The costs vary depending on one’s lifestyle and place of residence.</p>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwentynine">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentynine" aria-expanded="true" aria-controls="collapseTwentynine">
      Cities and Major Regional Centres
      </button>
    </h2>
    <div id="collapseTwentynine" className="accordion-collapse collapse show" aria-labelledby="headingTwentynine" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <ul className="list-group">
        <li className="list-group-item">Perth</li>
        <li className="list-group-item">Gold Coast</li>
        <li className="list-group-item">Sunshine Coast</li>
        <li className="list-group-item">Lake Macquarie</li>
        <li className="list-group-item">Illawarra</li>
        <li className="list-group-item">Geelong</li>
        <li className="list-group-item">Newcastle</li>
        <li className="list-group-item">Wollongong</li>
        <li className="list-group-item">Adelaide</li>
        <li className="list-group-item">Hobart</li>
        <li className="list-group-item">Canberra</li>
    </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
  <h2 className="accordion-header" id="headingThirty">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
    Funds to be shown
    </button>
  </h2>
  <div id="collapseThirty" className="accordion-collapse collapse" aria-labelledby="headingThirty" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p className="my-1">1. Tuition fees – 1 year</p>
        <p className="my-1">2. Living expenses – 1 year (AU$ 21,040)</p>
        <p className="my-1">3. Travel Fare – AU$2,000</p>
        <p className="mt-1">4. Insurance – AUS 4,000</p>

        <p>On an average a student has to show around INR 30 lacs for G-8 Universities and INR 25 lacs for
other universities.</p>

        <p>If a Master’s student is accompanied by spouse/child then living expenses and travel fare for the
companions are to be shown during the GTE assessment.
</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyOne">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyOne" aria-expanded="false" aria-controls="collapseThirtyOne">
    Proof of Funds
    </button>
  </h2>
  <div id="collapseThirtyOne" className="accordion-collapse collapse" aria-labelledby="headingThirtyOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
       <p>The funds to be shown for visa purposes should be maintained for 3 months either in the student’s
name or immediate family members’ name, i.e., Parents, Grandparents, Siblings and Spouse. Maternal
or Paternal Uncle and Aunt can sponsor for 20-25% of total funds.</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyTwo">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyTwo" aria-expanded="false" aria-controls="collapseThirtyTwo">
    Acceptable funds
    </button>
  </h2>
  <div id="collapseThirtyTwo" className="accordion-collapse collapse" aria-labelledby="headingThirtyTwo" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <p className='my-1'>1. Balance in Saving Bank Accounts</p>
    <p className='my-1'>2. Fixed Deposits & Balance Certificate</p>
    <p className='my-1'>3. PPF withdrawable amount</p>
    <p className='my-1'>4. Fully sanctioned Education Loan</p>
    <p className='mt-1'>5. ITRs – 2 to 3 years</p>

    <p>Note:- Documentary evidence to be provided in support of recent deposits and bulk amount of INR
50,000 and above. (if any)</p>


  <h6><strong>Universities in Australia prefer for 3 months old funds, but do accept recent funds if
appropriate sources are provided.</strong>  </h6>
    <ul>
      <li>Registered Sale Deed</li>
      <li>Liquidating Fixed Deposits</li>
      <li>Funds transferred from PF Accounts (GPF/EPF)</li>
      <li>Liquidating Mutual Funds/Shares</li>
      <li>Funds transferred from cooperative banks</li>
      <li>J-Forms, sale of car, etc. for relatively smaller deposits</li>
    </ul>

    </div>
  </div>
</div>

<div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Visa</span> Process</h4>
    </div>

<div className="accordion-item border">
  <h2 className="accordion-header" id="headingThirtyThree">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyThree" aria-expanded="false" aria-controls="collapseThirtyThree">
    Visa Application
    </button>
  </h2>
  <div id="collapseThirtyThree" className="accordion-collapse collapse" aria-labelledby="headingThirtyThree" data-bs-parent="#accordionExample">
    <div className="accordion-body">
     <h6>Student Visa Application Fees – AU$650 (to be paid by credit card only)</h6>
     <h6>Visa fees for dependents – Spouse – AU$485 & Child – AU$160</h6>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyFour">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyFour" aria-expanded="false" aria-controls="collapseThirtyFour">
    Document Checklist
    </button>
  </h2>
  <div id="collapseThirtyFour" className="accordion-collapse collapse" aria-labelledby="headingThirtyFour" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <ul>
            <li>Form 956 A</li>
            <li>eCoe</li>
            <li>OSHC letter (if purchased from a private provider)</li>
            <li>Referral letter (HAP ID) – generated for Doctor appointment from Immiaccount.</li>
            <li>Passport (all pages of all passports, old and new)</li>
            <li>Welfare arrangements (under 18 applicants)</li>
            <li>Statement of Purpose</li>
            <li>Academic documents</li>
            <li>Work experience documents (if applicable)</li>
            <li>Resume</li>
            <li>Financial Documents</li>
        </ul>

    </div>
  </div>
</div>

<div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Tips For</span> Students</h4>
    </div>

<div className="accordion-item border">
  <h2 className="accordion-header" id="headingThirtyFive">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyFive" aria-expanded="false" aria-controls="collapseThirtyFive">
    Part-time work
    </button>
  </h2>
  <div id="collapseThirtyFive" className="accordion-collapse collapse" aria-labelledby="headingThirtyFive" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <ul>
            <li>Full-time students can work for 20 hours per week or 40 hrs/fortnight.</li>
            <li>The average wage per hour is between AU$15-20. In Brisbane, it comes in the bracket of
AU$18-22. Regional Centres and areas offer higher wage rate.</li>
            <li>The student must get a Tax File Number (TFN) before starting work, to ensure that he/she is
not taxed at a high rate</li>
        </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtySix">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtySix" aria-expanded="false" aria-controls="collapseThirtySix">
    Type of jobs for international students
    </button>
  </h2>
  <div id="collapseThirtySix" className="accordion-collapse collapse" aria-labelledby="headingThirtySix" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <ul>
            <li>Retail – including supermarkets, department stores, boutiques</li>
            <li>Hospitality – cafes, bars, restaurants, delivery</li>
            <li>Farming and fruit-picking – seasonal work</li>
            <li>Services – childcare, aged care and cleaning</li>
            <li>Administration and clerical work</li>
            <li>Tutoring</li>
            <li>There are also charities and non-government organisations which offer volunteer work for
students – a great way to meet people, gain hands-on work experience and contribute to the
community.</li>
        </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtySeven">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtySeven" aria-expanded="false" aria-controls="collapseThirtySeven">
    Accommodation
    </button>
  </h2>
  <div id="collapseThirtySeven" className="accordion-collapse collapse" aria-labelledby="headingThirtySeven" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <ul>
        <li>Hall of residence – Offered by university.</li>
        <li>Homestay – Living with a family at their homes</li>
        <li>Apartments – Close to University campus. Popular with students in later year of their degrees.</li>
        <li>Hostels & Guesthouses – Temporary accommodation</li>
        <li>Private rental – You can rent or ‘lease’ a property by yourself or with friends.</li>
    </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyEight">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyEight" aria-expanded="false" aria-controls="collapseThirtyEight">
    Orientation
    </button>
  </h2>
  <div id="collapseThirtyEight" className="accordion-collapse collapse" aria-labelledby="headingThirtyEight" data-bs-parent="#accordionExample">
    <div className="accordion-body">
   <p>Orientation week is mandatory for international students they are introduced to the university and its
services, as well as get enrolled for classes.</p>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyNine">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyNine" aria-expanded="false" aria-controls="collapseThirtyNine">
    ESOS – Education Services for Overseas Students
    </button>
  </h2>
  <div id="collapseThirtyNine" className="accordion-collapse collapse" aria-labelledby="headingThirtyNine" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <h6>The following student rights are protected by law under the Education Services for Overseas Students
(ESOS):</h6>
        <ul>
            <li>The well-being of all international students.</li>
            <li>The quality of students’ education experience.</li>
            <li>The provision of up-to-date and accurate information.</li>
        </ul>
 
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingForty">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseForty" aria-expanded="false" aria-controls="collapseForty">
    Spouse Visa
    </button>
  </h2>
  <div id="collapseForty" className="accordion-collapse collapse" aria-labelledby="headingForty" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <ul>
            <li>Applicable in case of Masters students (only one year of academic gap is accepted by
Australian institutes and students going for Bachelors are way too younger)</li>
            <li>The marriage should be at least 1 year old.</li>
            <li>The duration and validity of dependent spouse visa will be usually the same as the student
visa</li>
            <li>As a dependent one is allowed to work for 40 hours fortnightly. If the spouse is studying
Masters or a doctoral degree then there is no restriction on the number of hours a dependent
can work. Kindly note that the student’s spouse should have at least the same education level
as the student.
</li>
        </ul>
    </div>
  </div>
</div>

    </div>
    </>
  )
}
