import React from 'react'
import gerimg1 from '../assets/newfolder/gerimg1.png'
import gerimg2 from '../assets/newfolder/gerimg2.png'
import gerimg3 from '../assets/newfolder/gerimg3.png'
import gerimg4 from '../assets/newfolder/gerimg4.png'
import gerimg5 from '../assets/newfolder/gerimg5.png'

export default function Gercomponent() {
  return (
    <>
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Why Study In Germany?
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src={gerimg1} className='img-fluid' alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Top German Universities
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <img src={gerimg2} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Public Universities Vs Private Universities
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg3} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Study In Germany
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg4} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Counselling and Profile Evaluation
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg5} className='img-fluid' alt="" />
      </div>
    </div>
  </div>
    
  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Latest </span>Update</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      APS
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>The APS is a joint institution of the German Embassy’s cultural department and the German Academic Exchange Service (DAAD). The APS checks the authenticity of educational biographies and the corresponding educational certificates, and issues certificates if the result is positive.</p>

        <p>It is mandatory for UG and PG both. An APS Certificate for Germany is proof of the authenticity of their Indian academic documents and is a prerequisite for your German Study visa application. The APS Certificate Germany is in place to simplify your application process to German universities and accelerate your student visa application.</p>
      </div>
    </div>
  </div>



    </div>
    </>
  )
}
