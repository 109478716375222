import React from 'react'
import gerimg1 from '../assets/newfolder/ireimg1.png'
import gerimg2 from '../assets/newfolder/ireimg2.png'
import gerimg3 from '../assets/newfolder/ireimg3.png'
import gerimg4 from '../assets/newfolder/ireimg4.png'
import gerimg5 from '../assets/newfolder/ireimg5.png'

export default function Irecomponent() {
  return (
    <>
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Map Of Ireland
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src={gerimg1} className='img-fluid' alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Facts about Ireland
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <ul>
            <li>Ireland is located in the north‐west of Europe.</li>
            <li>Politically, Ireland is divided between the Republic of Ireland and Northern Ireland, a part of the United Kingdom.</li>
            <li>Capital of Ireland is Dublin. Cork, Galway, Maynooth & Limerick are some of the popular cities of Ireland.</li>
            <li>Official language of Ireland is Irish and English.</li>
            <li>Population of Ireland is approximately 48 Lakhs and the Urban population of Dublin is approximately 13 Lakhs.</li>
            <li>Currency of Ireland is Euro €</li>
            <li>Flight duration from Mumbai/Delhi to Dublin: 12 hrs. 30 mins.</li>
        </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Why Study in Ireland?
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>A friendly, safe country.</li>
        <li>English speaking country.</li>
        <li>World class Universities.</li>
        <li>Low cost of tuition fees & accommodation.</li>
        <li>Internationally recognized qualifications & extensive choice of courses.</li>
        <li>European Headquarters of many IT & Pharmaceutical companies.</li>
        <li>Masters degree of 1 year.</li>
        <li>Post study work visa of 2 years for Masters.</li>
        <li>Post study work visa of 1 year for Bachelors (Hons).</li>
        <li>Chance to get indefinite right to stay (similar to Permanent Residency).</li>
      </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Education System in Ireland
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg2} className='img-fluid' alt="" />
      <ul>
        <li>There are total 9 Universities out of which 8 Government‐funded universities and 1 private German University providing undergraduate, postgraduate & research degree programmes.</li>
        <li>There are 11 government funded institutes of technology. They offer a wide variety of programmes which can be both academically and vocationally focused.</li>
        <li>Mostly private, they offer a range of programmes including professional certificates, diplomas, undergraduate & postgraduate degrees in a diverse range</li>
      </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Where to Study in Ireland?
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>GALWAY</li>
        <li>DUBLIN</li>
        <li>LIMERICK</li>
        <li>MAYNOOTH</li>
        <li>CORK</li>
      </ul>
      </div>
    </div>
  </div>


  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Tie-Up Institutions
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg3} className='img-fluid' alt="" />

      </div>
    </div>
  </div>



  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      List of Documents Required
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg4} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Entry Criteria
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <h6>Bachelors</h6>
        <ul>
            <li>Minimum 70%‐90% in class 12th for Universities</li>
            <li>70% in class 12th for Institute of Technologies</li>
            <li>55%‐60% in class 12th for Colleges</li>
        </ul>

        <h6>Masters</h6>
        <ul>
            <li>Minimum 55%‐75% in Graduation with IELTS 6.5* with no band less than 6.0.</li>
            <li>IELTS 6 with no band less than 5.5 is also acceptable in some Universities, Institute of Technologies and Colleges.</li>
        </ul>

        <p>Please note:- Student has to give IELTS/TOEFL/PTE as it is a mandatory requirement.</p>

      </div>
    </div>
  </div>

  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Post Study Work Rights
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <img src={gerimg5} className='img-fluid' alt="" />
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Admission</span> Process</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Process for Ireland
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul className="list-group">
        <li className="list-group-item">Application Submitted</li>
        <li className="list-group-item">Acceptance or Rejection</li>
        <li className="list-group-item">Fulfill the conditions if letter is conditional</li>
        <li className="list-group-item">Pay the Tuition Fees and get Final Acceptance Letter</li>
        <li className="list-group-item">Submit Visa File at VFS</li>
        <li className="list-group-item">Visa Decision Awaited</li>
        <li className="list-group-item">Visa Granted or Visa Rejection.</li>
        <li className="list-group-item">Stamped copy of VISA needs to be submitted to the respective institution</li>
        <li className="list-group-item">Student flies / Reaches Ireland.</li>
    </ul>
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Visa</span> Process</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Documents required for Student Visa
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <h6>Application Forms:</h6>
      <ul>
        <li>Printed and signed summary of online application form. The link is:- https://www.visas.inis.gov.ie/avats/OnlineHome.aspx</li>
        <li>Appointment confirmation</li>
        <li>Payment confirmation Visa fee of INR 5000 need to be paid online. The VFS service fee (INR 1700) need to be paid online.</li>
        <li>Supplementary Student Application Form: https://www.dfa.ie/media/embassyindia/NDVO</li>
      </ul>

       <h6>Passport</h6> 
       <ul>
        <li>Original Passport and photocopy of first and last page of the same.</li>
        <li>Recent02 photographs [35*45 80% face white back Matt finish] Student should submit all previous passports along with</li>
       </ul>

       <h6>Original Police Clearance Certificate from Passport Office.</h6>
       <ul>
        <li>Acceptance Letter & Confirmation of fees paid</li>
        <li>Statement of Purpose</li>
        <li>Working/Business Proofs of Sponsor</li>
       </ul>

       <h6>Academics</h6>
       <ul>
        <li>Brief CV.</li>
        <li>Class 10th Mark Sheet & Certificate.</li>
        <li>Class 12th Mark Sheet & Certificate.</li>
        <li>Graduation Mark Sheet & Certificate (In case of PG Course).</li>
        <li>IELTS/PTE score card (any one).</li>
        <li>Letter from Current Employer or Experience Certificate (where applicable).</li>
       </ul>

       <h6>Financial Documents</h6>
       <ul>
        <li>Affidavit of Support of Sponsor on50 rupees stamp paper.</li>
        <li>Bank Statement</li>
        <li>& Bank Balance Certificate (Last 6 Months): Total tuition fees+€10,000 living expenses for each year</li>
        <li>Bank Loan Sanction Letter and the documents of the property mortgaged with its valuation</li>
        <li>Income Tax Return Papers of the sponsor for the last 3 years</li>
        <li>Fixed deposit receipts should be submitted along with Fixed Deposit bank certificate [07 month old] (On bank letter head) Documents for Student Visa Contd.</li>
       </ul>

      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>GARDA</span> Registration</h4>
    </div>

    <div className="accordion-item border">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Details on GARDA Registration
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Initially, student will be getting visa for 30 to 90 days. Student will have to register at the Garda National Immigration Bureau (GNIB) soon after he/she arrives in Ireland.</p>
        <p>The below documents are required:-</p>
      <ul className="list-group">
        <li className="list-group-item">Passport</li>
        <li className="list-group-item">Student ID card (provided by the school/university in Ireland).</li>
        <li className="list-group-item">A letter from the Registrar’s office of the school/university stating that student is a registered student and stating the start date and completion date of his/her course.</li>
        <li className="list-group-item">The student is required to show a part of his/her living expenses i.e. a minimum of €3000 to the visa office in his/her Irish bank statement.</li>
        <li className="list-group-item">A €300 fee (credit/debit card ‐ no cash accepted).</li>
    </ul>
      </div>
    </div>
  </div>

  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Evidence of</span> Funds</h4>
    </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingTwelwe">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
      Evidence of Funds
      </button>
    </h2>
    <div id="collapseTwelwe" className="accordion-collapse collapse" aria-labelledby="headingTwelwe" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ul>
        <li>Evidence that the student has immediate access to Tuition Fees + Living expenses for all the years. €10,000 is the estimated cost of living in Ireland as per Irish Embassy for a student for one academic year. The cost of total duration of study and stay needs to be shown in liquid funds such as Education Loan, Bank Balances and Fixed Deposits</li>
        <li>It is highly recommended that the tuition fee should not be sponsored only through the education loan, which means the sponsor should have 6 months old liquid funds in their account apart from education loan</li>
        <li>To get faster VISA, it is advised to make full fees payment before applying for VISA.</li>
        <li>If there are recent large deposits in the account then a proper source of those funds also needed to be submitted.Evidence of deposits, registration and tuition fees already paid AND evidence of the source of funds used to pay them to be shown.</li>
        <li>In case of education loan, the monthly income of parents should be sufficiently more to cover the other living expenses of the sponsors/parents and the EMI of the education loan.</li>
        <li>Sponsors should have strong annual income and below listed documentary evidences can be accepted</li>
       </ul>
      </div>
    </div>
  </div>


    </div>
    </>
  )
}
