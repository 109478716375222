import React from 'react'

export default function Canada5sec() {
  return (
    <>
        <div className="accordion" id="accordionExample">
    <div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortyThree" aria-expanded="false" aria-controls="collapseFortyThree">
    What are the requirements in order to obtain a work permit, while studying in Canada?
    </button>
  </h2>
  <div id="collapseFortyThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>To work in Canada after you graduate, you need a work permit. The work experience you gain while working may help you qualify for permanent residence.</p>
      <p>Post-graduation work permit (PGWP)</p>
      <p>Graduates of certain designated learning institutions are eligible for this work permit.</p>
      <p>Fees: PGWP: $255 CAN  Restoration of student status and PGWP: $605 CAN</p>
      <p>Processing time   Online: 159 days      Paper: 145 days</p>
      <p>How long your PGWP is valid for depends on the length of your study program.</p>
      <p>If your program was less than 8 months:You aren’t eligible for a PGWP.</p>
      <p>If your program was at least 8 months, but less than 2 years:They  may give you a PGWP that’s valid for up to the same length as your study program.</p>
      <p>For example, if you completed a 9-month program, we may give you a work permit for up to 9 months.</p>
      <p>If your program was 2 years or more:They  may give you a PGWP that’s valid for 3 years.</p>
      <p>If you completed more than 1 program:You may be able to get a PGWP that combines the length of each program.</p>
      <p>Each of the programs you completed must be PGWP-eligible and at least 8 months in length</p>
      <p>You can’t get a PGWP if you already had one after completing an earlier program of study.</p>
      <p>If your passport expires before your PGWP,make sure your passport is valid for the entire length of time you should be eligible for. If your passport expires earlier they’ll only issue you a PGWP that’s valid until your passport expires.</p>
      <h6>Other types of work permits</h6>
      <p>If you’re not eligible for a PGWP, you may still be able to work in Canada after you graduate. Most people need a work permit to work in Canada. If you need a work permit, you may be eligible for either an employer-specific work permit or open work permit</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortyFour" aria-expanded="false" aria-controls="collapseFortyFour">
    How Do I Become a Permanent Resident in Canada?
    </button>
  </h2>
  <div id="collapseFortyFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>This depends on which immigration program you choose. If you apply through programs such as the Express Entry System or a Provincial Nominee Program and are successful, you will receive an Invitation to Apply (ITA) for permanent residence.  Be sure to have all your supporting documentation ready as you will have 90 days to accept your invitation. Once your application has been assessed and approved you will receive a Confirmation of Permanent Residency (COPR).</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortyFive" aria-expanded="false" aria-controls="collapseFortyFive">
    Pilot Programs
    </button>
  </h2>
  <div id="collapseFortyFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <ul>
        <li className="my-2">The Atlantic Immigration Pilot</li>
        <li className="my-2">The Rural and Northern Immigration Pilot</li>
        <li className="my-2">The Agri-food Pilot</li>
        <li className="my-2">The Home Care Provider Pilots</li>
      </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFortySix" aria-expanded="false" aria-controls="collapseFortySix">
    TAXES IN CANADA
    </button>
  </h2>
  <div id="collapseFortySix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
    <div className="accordion-body">
      <p>Welcome! We’re happy you’re here. Just remember as an international student studying in Canada, you may have to file a Canadian income tax and benefit return. We want to make sure that you have everything you need to file, so here’s what you need to do!</p>
      <p>In Canada, roads, public utilities, schools, health care, economic development and cultural activities are paid for by the residents of Canada. The federal, provincial and municipal governments collect money from individuals and companies to help pay for government programs and services. Common types of taxes are income taxes, sales taxes, property taxes, and business taxes (if you own a business).</p>
      <p>Income Tax</p>
      <p>This individual tax is collected by the Canada Revenue agency. Canadian residents fill in an income tax return every year.</p>
      <p>Sales Tax</p>
      <p>In Canada, three types of sales taxes are levied. These are as follow</p>
      <p>Provincial sales taxes (PST), levied by the provinces</p>
      <p>Goods and Services Tax (GST), a value-added tax levied by the federal government</p>
      <p>Only some provinces have the combined Harmonized Sales Tax (HST) which is a value-added tax, a single, blended combination of the PST and GST. This is used in Ontario, (formerly) British Columbia, and the Atlantic provinces; New Brunswick, Newfoundland and Labrador, Nova Scotia and Prince Edward Island. The HST is collected by the Canada Revenue Agency, which then remits the appropriate amounts to the participating provinces.</p>
      <p>Every province except Alberta has implemented either a provincial sales tax or the Harmonized Sales Tax. The federal GST rate is 5 percent, effective January 1, 2008.</p>
      <p>The territories of Yukon, Northwest Territories, and Nunavut have neither the HST nor territorial sales taxes and only the GST is collected. This is because the cost of living in the North is so high and the residents of these outlying areas are heavily subsidized by the federal government.</p>
    </div>
  </div>
</div>

</div>

    </>
  )
}
