import React from 'react'

export default function Gernarlinfousa() {
  return (
    <>
        <div className="accordion" id="accordionExample">

        <div className="accordion-item">
    <h2 className="accordion-header" id="headingThirteen">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirteen" aria-expanded="false" aria-controls="collapseNine">
      Grading System in USA
      </button>
    </h2>
    <div id="collapseThirteen" className="accordion-collapse collapse show" aria-labelledby="headingThirteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <p>The GPA (Grade Point Average) system is followed throughout the United States to judge student’s performance.</p>
       <p>Graded in scale of 0 to 4</p>
       <div className="table-responsive">
  <table className="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Percentage Range</th>
        <th>Grade Point Average (USA)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>50% – 58%</td>
        <td>2.66</td>
      </tr>
      <tr>
        <td>58% – 60%</td>
        <td>3.00</td>
      </tr>
      <tr>
        <td>60% – 70%</td>
        <td>3.33</td>
      </tr>
      <tr>
        <td>70% – 80%</td>
        <td>3.66</td>
      </tr>
      <tr>
        <td>80% +</td>
        <td>4.00</td>
      </tr>
    </tbody>
  </table>
</div>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingForteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseForteen" aria-expanded="false" aria-controls="collapseForteen">
      General Entry Requirements
      </button>
    </h2>
    <div id="collapseForteen" className="accordion-collapse collapse" aria-labelledby="headingForteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ul>
        <li>Undergraduate: 10+2</li>
        <li>Graduate:16 years of education required (10+2+4)</li>
        <li>Some institutions accept 15 years education into their Bridge /Masters Programs</li>
        <li>Foundations/Pathways programs are available for students who have secured below 50 or 60%.</li>
        <li>Age: The student should have completed 18 years of age before joining a degree program.</li>
        <li>WES Evaluation of academic credentials is required in some universities.</li>
        <li>Bachelor (Undergraduate): minimum academic requirement is 10+2 with 50%&TOEFL-iBT 61 to 80 or IELTS 5 to 6.0 bands and SAT (optional)</li>
        <li>Master of Science / Engineering (Graduate): minimum academic requirement is 10+2+4 with 55% & TOEFL-iBT 71 to 80 between or IELTS 0 to 6.5 bands and GRE score 290+.</li>
       </ul>
       <p>(Note: There are some Universities, allow 12+3 and offers program without GRE).</p>
       <p>MBA: minimum academic requirement is 10+2+3or 10+2+4 &TOEFL-iBT 71 to 80 between or IELTS 6.0 to 6.5 bands and GMAT score between 550 to 750.</p>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFifteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFifteen" aria-expanded="false" aria-controls="collapseFifteen">
      Getting Admission for Bachelor’s
      </button>
    </h2>
    <div id="collapseFifteen" className="accordion-collapse collapse" aria-labelledby="headingFifteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ul>
        <li>A minimum of 50 – 60% in academics is necessary.</li>
        <li>IELTS must be at least 5 – 6.0 bands.</li>
        <li>SAT is required for admission some good universities, it is recommended for for other SAT score should be a minimum of 1070</li>
        <li>Getting good SAT score helps in getting scholarships and Visa also.</li>
        <li>SAT is not mandatory as we have plenty of partner universities without SAT as well</li>
       </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSixteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSixteen" aria-expanded="false" aria-controls="collapseSixteen">
      Getting Admission for Master’s: (10+2+4/3 year's education pattern)
      </button>
    </h2>
    <div id="collapseSixteen" className="accordion-collapse collapse" aria-labelledby="headingSixteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>Higher Second Class in academics is necessary.</li>
        <li>IELTS must be 6.0 bands at least.</li>
        <li>GRE for MS programs and GMAT for Business programs highly recommended for Admission </li>
        <li>A minimum of 300+ in GRE and 550 in GMAT is advisable.</li>
        <li>Maximum of 3 to 5 backlogs are accepted</li>
        <li>Average Tuition Fees vary from a minimum of 15000 USD to 25000 USD per annum and it</li>
        <li>Getting good GRE/GMAT score helps in getting admission in good University, Scholarships </li>
      </ul>

        <h6>List of Universities for MBA in USA with 15 years of Education</h6>
        <p>1. Adelphi University, New York</p>
        <p>2. Ashland University, Ohio</p>
        <p>3. Gannon University, Erie, Pennsylvania</p>
        <p>4. Indiana Tech, Fort Wayne, Indiana</p>
        <p>5. Murray State University, Kentucky</p>
        <p>6. National University, San Diego, CA</p>
        <p>7. Pace University, New York City</p>
        <p>8. Saginaw Valley State University, Saginaw, Michigan</p>
        <p>9. Saint Leo University, Florida</p>
        <p>10. Southern New Hampshire University, Manchester, NH</p>

        <p>US Universities Accepting 15 Years Of Education For MS In Computer Science</p>
        <br />
        <h6>List of Universities in the USA Accepting 3-Year Bachelor’s Degree</h6>
        <p>1. Massachusetts Institute of Technology (MIT)</p>
        <p>2. Columbia University</p>
        <p>3. Princeton University</p>
        <p>4. Yale University</p>
        <p>5. Wharton School of Business, University of Pennsylvania</p>
        <p>6. Carnegie Mellon University</p>
        <p>7. University of North Carolina</p>
        <p>8. California State University</p>
        <p>9. University of Nevada</p>
        <p>10. Darden School of Business, University of Virginia</p>
        <p>11. Northwestern University</p>
        <p>12. Tuck School of Business, Dartmouth</p>
        <p>13. University of North Carolina, Charlotte</p>
        <p>14. Zicklin School of Business, Baruch College</p>
        <p>15. University of California, Berkeley</p>
        <p>16. University of California, San Diego</p>
        <p>17. John Hopkins University</p>
        <p>18. University of Maryland, College Park</p>
        <p>19. New York University (NYU)</p>
        <p>20. University of Texas, Dallas</p>
        <p>21. University of Texas, Arlington</p>
        <p>22. Georgia State University</p>

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingSeventeen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeventeen" aria-expanded="false" aria-controls="collapseSeventeen">
      For All Engineers and Health Care programs: (10+2+4 year's education pattern)
      </button>
    </h2>
    <div id="collapseSeventeen" className="accordion-collapse collapse" aria-labelledby="headingSeventeen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ul>
        <li>Higher Second Class in academics is necessary.</li>
        <li>IELTS must be 6.0 – 6.5 bands. Some programs may have higher requirement.</li>
        <li>GRE / GMAT is highly recommended for Admission and Visa point of view. GRE is mostly accepted instead of GMAT. A minimum of 290 in GRE and 550 in GMAT is advisable.</li>
        <li>Maximum of 10 to 12 backlogs are accepted. Only few Universities accepts more than that Backlogs</li>
        <li>Once you actually plan to go to USA, then start the process at least 4-6 months before ,but students can still apply</li>
        <li>Average Tuition Fees vary from a minimum of 15000 USD to 45000 USD and it depends on university</li>
        <li>Getting good GRE/GMAT score helps in getting admission in good University, Scholarships and visa also</li>
       </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingEighteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEighteen" aria-expanded="false" aria-controls="collapseEighteen">
      For M.Sc. / MBA / MA:
      </button>
    </h2>
    <div id="collapseEighteen" className="accordion-collapse collapse" aria-labelledby="headingEighteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <ul>
        <li>Higher Second Class in academics is necessary</li>
        <li>IELTS must be 6.0 – 6.5 bands (for MBA, some universities may ask higher score)</li>
        <li>GRE / GMAT is highly recommended or Admission and Visa point of view GRE is mostly accepted instead of GMAT</li>
        <li>A minimum of 300 in GRE and 550 in GMAT is advisable</li>
        <li>Maximum of 10 to 12 backlogs are accepted .</li>
        <li>Average Tuition Fees vary from a minimum of 15000 USD to 45000 USD and it depends on university</li>
        <li>Getting good GRE/GMAT score helps in getting admission in good University, Scholarships and visa also</li>
       </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingNineteen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNineteen" aria-expanded="false" aria-controls="collapseNineteen">
      Difference Between OPT and CPT
      </button>
    </h2>
    <div id="collapseNineteen" className="accordion-collapse collapse" aria-labelledby="headingNineteen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
       <h6>Optional Practical Training (OPT)</h6>
       <ul>
  <li>OPT is a temporary authorization offered to students on F-1 visa to work on their related field for a period of total 12 months.</li>
  <li>OPT Eligibility Requirement: The students must have been pursuing their course full-time for at least one full academic year.</li>
  <li>OPT in order to receive OPT authorization, students do not require any employment or job offer.</li>
  <li>OPT program has two variants— Pre-completion OPT and Post-completion OPT. Students can pursue OPT while they are still in their academics with Pre-completion OPT. And, with Post-completion OPT, students can start their OPT after their graduation.</li>
  <li>Students require OPT to work for a total period of 12 months (Pre or Post Completion OPT). Yet, students have an option to extend their OPT for another 17 months with STEM OPT extension; this is only applicable to certain majors.</li>
  <li>With OPT, students’ employment experience must be related to their majors or field of study and it doesn’t have to be a part of their academic curriculum.</li>
  <li>OPT students can work part-time when University/college is in term; during vacation students can work full-time.</li>
  <li>Students on OPT can work with any employer across the US as long as their OPT jobs are related to their field of study.</li>
</ul>
<br />
<h6>Curricular Practical Training (CPT)</h6>
<ul>
  <li>CPT is a temporary work authorization provided to the students on F-1 visa, which is directly related to their majors for a period of total 12 months.</li>
  <li>CPT Eligibility Requirement: If the students complete their full-time CPT for a period of 12 months then they would be no longer eligible for OPT. Besides, the students must have been enrolled full-time for at least one academic year.</li>
  <li>While applying for CPT, students require a job or employment offer.</li>
  <li>CPT is for a total of 12 months and it can be either full-time or part-time.</li>
  <li>Having CPT to work, students can be on the job for a total period of 12 months. But, students will not have the option to work on OPT once they complete their 12-month CPT.</li>
  <li>With CPT, students’ employment experience must be directly related to their major or field of study. Plus, it must be a part of students’ curriculum; this implies, students’ employment experience must offer credits in their curriculum.</li>
  <li>CPT students can work part-time when University/College is in term; during off-session, students can work full-time.</li>
  <li>Students on CPT can work only with employers that are on the Student and Exchange Visitor Information System (SEVIS) and I-20 list.</li>
</ul>


      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwenty">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwenty" aria-expanded="false" aria-controls="collapseTwenty">
      Transcript Evaluation
      </button>
    </h2>
    <div id="collapseTwenty" className="accordion-collapse collapse" aria-labelledby="headingTwenty" data-bs-parent="#accordionExample">
      <div className="accordion-body">
<p>When applying to US universities, you may need to provide the US equivalency of your academic records from your home country through a process called 'Credential Evaluation,' where organizations use evaluation standards to accurately translate your grades and serve as a trustworthy third party to both international students and US universities.</p>

      
<p>Credential evaluation services are usually conducted by third-party organizations that specialize in transcript evaluation. As part of this service, an expert evaluator analyzes your academic and professional transcripts, certifications, and degrees earned in your home country and determines their US equivalents.</p>

       <h6>List of Credential Evaluators For International Students</h6>
       <p>American Evaluation Association (AEA)</p>
       <p>Educational Credential Evaluators (ECE)</p>
       <p>World Education Services (WES)</p>

      </div>
    </div>
  </div>

  <div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyOne">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyOne" aria-expanded="false" aria-controls="collapseTwentyOne">
    List of Documents Required For Application:
    </button>
  </h2>
  <div id="collapseTwentyOne" className="accordion-collapse collapse" aria-labelledby="headingTwentyOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <ul>
  <li>10th and 12th Mark sheets with Passing Certificate</li>
  <li>Bachelor’s Transcripts</li>
  <li>Bachelor’s Degree Certificate/Provisional Certificate/Bonafide Certificate Transcript from University</li>
  <li>A professional CV</li>
  <li>3 Recommendation Letters (Professional/Academic)</li>
  <li>Statement of Purpose</li>
  <li>Bank Letter for financial support</li>
  <li>Affidavit of Support</li>
  <li>Test scores sheet (IELTS/TOEFL-iBT/PTE/GRE/GMAT/SAT)</li>
  <li>Passport Copy</li>
  <li>Work Experience Letters (if any)</li>
</ul>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyTwo">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyTwo" aria-expanded="false" aria-controls="collapseTwentyTwo">
    Standardized Tests
    </button>
  </h2>
  <div id="collapseTwentyTwo" className="accordion-collapse collapse" aria-labelledby="headingTwentyTwo" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>For undergraduate admissions, required standardized tests usually include:</p>
        <ul>
  <li>Test of English as a Foreign Language (TOEFL)</li>
  <li>Graduate Record Examinations (GRE) – for liberal arts, science, math</li>
  <li>Graduate Management Admission Test (GMAT) – for business schools/study for MBA (Master’s in Business Administration) programs</li>
  <li>Law School Admission Testing Program (LSAT) – for law schools</li>
  <li>Medical College Admission Test (MCAT) – for medical schools</li>
  <li>Dental Admission Testing Program (DAT) – for dental schools</li>
  <li>Pharmacy College Admission Test (PCAT)</li>
  <li>Optometry Admission Testing Program (OAT)</li>
</ul>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyThree">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyThree" aria-expanded="false" aria-controls="collapseTwentyThree">
    SAT Vs ACT
    </button>
  </h2>
  <div id="collapseTwentyThree" className="accordion-collapse collapse" aria-labelledby="headingTwentyThree" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <img src="/img/usimg2.png" className='img-fluid' alt="" />
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyFour">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFour" aria-expanded="false" aria-controls="collapseTwentyFour">
    GRE Vs GMAT
    </button>
  </h2>
  <div id="collapseTwentyFour" className="accordion-collapse collapse" aria-labelledby="headingTwentyFour" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <img src="/img/usimg3.png" className='img-fluid' alt="" />
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyFive">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyFive" aria-expanded="false" aria-controls="collapseTwentyFive">
    Fall Semester Ideal Application process timeline
    </button>
  </h2>
  <div id="collapseTwentyFive" className="accordion-collapse collapse" aria-labelledby="headingTwentyFive" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src="/img/usimg4.png" className='img-fluid' alt="" />
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentySix">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySix" aria-expanded="false" aria-controls="collapseTwentySix">
    Optional Practical Training (OPT)
    </button>
  </h2>
  <div id="collapseTwentySix" className="accordion-collapse collapse" aria-labelledby="headingTwentySix" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>Optional Practical Training (OPT) is defined in the regulations as “temporary employment for practical training directly related to the student’s major area of study.” F-1 students must have completed one full academic year of full-time study before being eligible to apply.</p>

        <p>There are three general types of Optional Practical Training:</p>
        <ol>
  <li>Pre-completion OPT – only available during the degree program</li>
  <li>Post-completion OPT – F-1 students must apply within 90 days prior to the date all degree requirements are met, or during the 60-days grace period.</li>
  <li>STEM OPT Extensions – available for certain Science, Technology, Engineering, and Mathematics (STEM) F-1 students must apply within 90 days prior to the ending date of the current Post-Completion OPT</li>
</ol>

<p>You can apply for the 24-month OPT extension if you meet the following conditions:</p>

<ul>
  <li>Currently working in a Post-Completion OPT period</li>
  <li>Obtained a bachelor’s, master’s, or doctoral level STEM degree from a SEVP-certified college or university</li>
  <li>Wish to work (or are currently working) for an employer who uses the E-Verify program</li>
  <li>You and your employer have signed Form I-983, “Training Plan for STEM OPT Students”</li>
</ul>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentySeven">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentySeven" aria-expanded="false" aria-controls="collapseTwentySeven">
    Curriculum Practical Training
    </button>
  </h2>
  <div id="collapseTwentySeven" className="accordion-collapse collapse" aria-labelledby="headingTwentySeven" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>Curricular Practical Training (CPT) is a program that temporarily allows international students with an F-1 visa to gain practical experience directly related to their major through employment, paid or unpaid internships, or cooperative (co-op) education.</p>

        <ul>
  <li>CPT is a part of the curriculum and must be completed before graduating</li>
  <li>CPT can be part-time (20 hours or less a week) or full-time (20 hours or more a week)</li>
  <li>CPT will require a letter from your employer, and your employment will be applicable only for a specific period</li>
</ul>

<h6>Difference Between OPT and CPT?</h6>
    <p>The major difference between OPT and CPT lies in the eligibility period and the nature of work permitted. OPT can be undertaken either before or after graduation, whereas CPT must be completed prior to graduation. CPT employment constitutes part of your major curriculum, enabling students to engage in paid or unpaid internships, practicums, or cooperative (co-op) education programs. Participation in CPT requires it to be a requirement of your major, or if not, you must receive course credit. Moreover, only specific employers participate in CPT. On the other hand, OPT is not tied to a specific employer and allows for work, rather than an internship or co-op, without the necessity of earning course credit.</p>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingTwentyEight">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentyEight" aria-expanded="false" aria-controls="collapseTwentyEight">
    STEM & Non-STEM
    </button>
  </h2>
  <div id="collapseTwentyEight" className="accordion-collapse collapse" aria-labelledby="headingTwentyEight" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <ul>
  
    <ul>
      <li>STEM: Stands for science, technology, engineering, and mathematics, encompassing subjects falling under these disciplines</li>
      <li>STEM students often secure the highest starting salaries and have promising career opportunities</li>
      <li>International students on F1 visas who graduate from STEM courses are allowed to extend their OPT period by 2 years</li>
    </ul>
</ul>

    </div>
  </div>
</div>

        </div>
    </>
  )
}
