import React from 'react'
import { Link, NavLink } from "react-router-dom";

export default function Redirect(props: { title: string; }) {

    document.title = props.title;
  return (
    <>
    <div className="container text-center" style={{marginTop:'10rem'}}>
    <section className="page_404">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 ">
        <div className="col-sm-12 col-sm-offset-1  text-center">
          <div className="four_zero_four_bg">
            {/* <h1 className="text-center ">404</h1> */}

          </div>

          <div className="contant_box_404">
            <h3 className="h1">
              Looks like you're lost
            </h3>

            <p>The page you are looking for is not available!</p>

            <Link to="/" className="link_404">Go to Home</Link>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</div>
    </>
  )
}
