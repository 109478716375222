import React from 'react'

export default function Packages() {
  return (
    <>
    <div className="container" style={{marginTop:'10rem'}}>
    <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ93qZnHWcwcUZ0bO1OgHmv1Ecq1RNpAsj67Y-qWgd28S31Taql1AcIYOJSWy33Sa4bQ7SUyLx1kIhV/pubhtml?widget=true&amp;headers=false" width="100%" height="650px"></iframe>
    </div>
    </>
  )
}
