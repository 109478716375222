import React from 'react'

export default function Visainfous() {
  return (
    <>
            <div className="accordion" id="accordionExample">

        <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwentynine">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwentynine" aria-expanded="true" aria-controls="collapseTwentynine">
      Documents required to obtain I-20
      </button>
    </h2>
    <div id="collapseTwentynine" className="accordion-collapse collapse show" aria-labelledby="headingTwentynine" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>Certificate of Eligibility for Nonimmigrant (F-1) Student Status-For Academic and Language Student</p>
     <ul>
        <li>Certificate of Declaration of Finances(varies from institution)</li>
        <li>Affidavit of Support(refer to the format shared)</li>
        <li>Bank Certificate (refer to the format shared)</li>
     </ul>
     <p>Note: Please always share these three requirements with student after obtaining the offer of admission</p>
     <p>There are certain universities which require the above listed documents at the time of application of also</p>

     <h6>Sponsors and Acceptable Funds</h6>
     <p>Self Funded ‐ Parents.</p>

     <ul>
  <li>Balance in saving account in Scheduled Public/Private/MNC Banks</li>
  <li>Balance in saving account/monthly income scheme/recurring deposit in Post Office</li>
  <li>Fixed Deposits (FDR) with banks</li>
  <li>Withdrawable balance in PPF, GPF, EPF, or CMPF</li>
  <li>Loans from nationalized banks or private sector banks. A guarantor may be required</li>
</ul>

    
    <img src="/img/usimg5.png" className='img-fluid' style={{width:'15rem'}} alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
  <h2 className="accordion-header" id="headingThirty">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirty" aria-expanded="false" aria-controls="collapseThirty">
    F1 Visa Cycle
    </button>
  </h2>
  <div id="collapseThirty" className="accordion-collapse collapse" aria-labelledby="headingThirty" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src="/img/usimg6.png" className='img-fluid' alt="" />
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyOne">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyOne" aria-expanded="false" aria-controls="collapseThirtyOne">
    Documents required for Visa Application
    </button>
  </h2>
  <div id="collapseThirtyOne" className="accordion-collapse collapse" aria-labelledby="headingThirtyOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <h6>Set 1: Personal Information</h6>
        <ul>
  <li>Passport (current and all the previous ones)</li>
  <li>MRV Fee Receipt</li>
  <li>Visa Interview Appointment Letter</li>
  <li>DS 160 Confirmation Slip (with the bar code clearly visible)</li>
  <li>SEVIS Fee Receipt</li>
  <li>Passport Size Photograph (as per regulations mentioned)</li>
</ul>
<br />
<h6>Set 2: Educational Documents</h6>
<ul>
  <li>Academic Transcripts like Class 10th and 12th Certificates and mark-sheets</li>
  <li>GRE/GMAT/SAT scorecard</li>
  <li>IELTS/TOEFL/PTE scorecard</li>
  <li>Bachelor’s Degree/Provisional Degree Certificate or course completion certificate or mark-sheet for all the years as well as the consolidated mark sheet (if applicable)</li>
</ul>
<br />
<h6>Set 3: Work-related Documents (if applicable)</h6>
<ul>
  <li>Joining Letter</li>
  <li>Salary Slips (last six months)</li>
  <li>Relieving Letter</li>
</ul>
<br />
<h6>Set 4: Financial Documents</h6>
<ul>
  <li>Saving Bank Account Statement (recommended: last three years)</li>
  <li>Fixed Deposit Certificates (recommended: last three years)</li>
  <li>Tax Returns for the past three years</li>
  <li>Property Documents (residential, commercial, ancestral, and/or farmland)</li>
</ul>


    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyTwo">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyTwo" aria-expanded="false" aria-controls="collapseThirtyTwo">
    Types of Visa
    </button>
  </h2>
  <div id="collapseThirtyTwo" className="accordion-collapse collapse" aria-labelledby="headingThirtyTwo" data-bs-parent="#accordionExample">
    <div className="accordion-body">
  <h6><strong>F1 Visa:</strong>  </h6>
    <ul>
      <li>An F1 visa is issued to international students who are attending an academic program or English Language Program at a US college or university.</li>
      <li>F–1 students must maintain the minimum course load for full-time student status.</li>
      <li>Students applying for a program that requires more than 18 hours of study in a week require an F1 visa. This includes all undergraduate programs as well as graduate programs like MS, MBA, etc.</li>
      <li>Spouses or children accompanying F-1 visa recipients will travel on an F-2 visa. Please note that spouses are not able to work but may accompany and/or apply for their own visa to the U.S. to work or study.</li>
</ul>

  <h6><strong>M1 Visa:</strong>  </h6>
    <ul>
      <li>An M1 visa is a temporary student visa that allows international students to attend an accredited vocational or non-academic school, such as a trade school or practical training school.</li>
      <li>International students wishing to enroll in academic study or English language studies may be able to apply for an F1 Visa.</li>
      <li>The M1 visa is reserved for vocational and technical schools.</li>
      <li>While the process for applying to the F1 visa and M1 visa is similar, the difference is that on entering, the M1 visas are time-stamped and students cannot overstay their visit.</li>
    </ul>

    <h6><strong>Exchange Visitor (J-1) Visa:</strong>  </h6>
    <ul>
      <li>The J-1 Exchange Visitor Visa is for students, visiting scholars, or lecturers pursuing an exchange program. Examples include Fulbright scholars and students on short-term study abroad programs.</li>
      <li>The J-1 visa is usually sought by working professionals who participate in exchange programs. These may include 10-month vocational training or research fellowships.</li>
      <li>Applicants will be notified by their respective institutions regarding their eligibility for the J-1 visa.</li>
      <li>Spouses or children accompanying J-1 Visa recipients will travel on a J-2 Visa. Spouses are able to work when permission is obtained in advance.</li>
    </ul>


    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyThree">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyThree" aria-expanded="false" aria-controls="collapseThirtyThree">
    Work Opportunities
    </button>
  </h2>
  <div id="collapseThirtyThree" className="accordion-collapse collapse" aria-labelledby="headingThirtyThree" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <ul>
        <li>How many international students are there in the United States?</li>
        <li>According to the report, the total number of international students enrolled in U.S. colleges was more than 1 million during the 2018-19 academic year.</li>
        <li>Can international students do part-time jobs in the USA?</li>
        <li>YES, international students can work in the USA while studying but there are some restrictions.</li>
      <li>International students who have an F-1 and M-1 visa are allowed to work on-campus and in specified training programs.</li>
      <li>Students are not allowed to work off-campus during their first academic year.</li>
      <li>Students are allowed to work only 20 hours a week and can work only on-campus.</li>
        </ul>

        <h6>Most popular on-campus jobs are:</h6>
        <ul>
      <li>Library Monitor</li>
      <li>Teaching Assistant</li>
      <li>Tour Guide</li>
      <li>Peer Tutor</li>
      <li>Academic Department Assistant</li>
      <li>Campus Tech Support</li>
      <li>Production Assistant</li>
      <li>Barista</li>
    </ul>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyFour">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyFour" aria-expanded="false" aria-controls="collapseThirtyFour">
    Types of Employment available to F‐1 Students while studying or after studies
    </button>
  </h2>
  <div id="collapseThirtyFour" className="accordion-collapse collapse" aria-labelledby="headingThirtyFour" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <h6>On Campus Employment</h6>
        <ul>
  <li>Can work on campus 20 hours per week during study time.</li>
  <li>Can work full-time (i.e., 40 hours per week) during holidays.</li>
  <li>Positions include:</li>
      <li>Teaching Assistantship</li>
      <li>Research Assistantship</li>
      <li>Fellowship</li>
      <li>Hourly Employment - Food Services, Library, Event Coordinator, Laboratory Assistant, etc.</li>
</ul>

<h6>Off Campus Employment through</h6>
<p>1. Curricular Practical Training (CPT) : Provides opportunity to gain actual employment experience.</p>
<ul>
  <li>“An integral part of an established curriculum”.</li>
  <li>Defined as “alternate work/study, internship, cooperative education, or any other type of required internship or practicum which is offered by sponsoring employers through cooperative agreements with the school.”</li>
  <li>To be eligible for CPT, a student must have completed 9 months in lawful status and currently be in F‐1 status.</li>
  <li>CPT can be full-time (20 hours per week) or part-time (20 hours or less per week).</li>
</ul>
<br />
<p>2. Optional Practical Training (OPT):- Available to students who are lawfully enrolled on a full – time basis &have completed at least 1 full academic year .</p>
<ul>
  <li>Eligible students may request OPT in order to be employed in a position related to the major area of study.</li>
  <li>12/36 months of OPT are available after each degree level completion.</li>
</ul>



    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyFive">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyFive" aria-expanded="false" aria-controls="collapseThirtyFive">
    General Steps to Get OPT
    </button>
  </h2>
  <div id="collapseThirtyFive" className="accordion-collapse collapse" aria-labelledby="headingThirtyFive" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <img src="/img/usimg7.png" className='img-fluid' alt="" />
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtySix">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtySix" aria-expanded="false" aria-controls="collapseThirtySix">
    GlobEDwise Partners
    </button>
  </h2>
  <div id="collapseThirtySix" className="accordion-collapse collapse" aria-labelledby="headingThirtySix" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <p>Globedwise has direct tie up with recruitment partners like Shorelight,INTO,Study Group,Navitas,Educo Accelerate.Also some universities are directly represented by GLOBEDWISE</p>

        <p>For most updated information and reference resource material,please refer to the link:</p>

        <p>Shorelight:- Find Universities | Shorelight</p>
        <p>Study Group:- International student recruitment in North America | Study Group</p>
        <p>INTO:- Partner Portal (intoglobal.com)</p>
        <p>Navitas:- USA – Navitas Agents</p>
    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtySeven">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtySeven" aria-expanded="false" aria-controls="collapseThirtySeven">
    Frequently Asked Questions
    </button>
  </h2>
  <div id="collapseThirtySeven" className="accordion-collapse collapse" aria-labelledby="headingThirtySeven" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <h6>Q: There are so many schools in the U.S. How do I decide which schools to apply to?</h6>
<p>A: Research your options and define your priorities. Contact the EducationUSA advising center nearest you and browse college search engines online. Check to see if the schools you are considering are accredited.</p>

<h6>Q: What’s the difference between a college and a university?</h6>
<p>A: Colleges offer only undergraduate degrees while universities offer graduate degrees as well, but the terms are often used interchangeably.</p>

<h6>Q: Are there age limitations to attend U.S. universities?</h6>
<p>A: In general, you must have completed high school and you must be at least 17 years of age.</p>

<h6>Q: What is the academic calendar for universities in the United States?</h6>
<p>A: The academic year usually runs from August through May with breaks for holidays. Most universities use either the semester system (two terms), the quarter system (students attend three out of four total terms), or the trimester system (three terms).</p>

<h6>Q: What is the difference between “Undergraduate” and “Graduate” degrees?</h6>
<p>A: Undergraduate programs follow high school and lead to an associate (two-year) degree or a bachelor (four-year) degree. Graduate programs follow a bachelor’s degree and lead to a master’s or doctoral degree.</p>

<h6>Q: What are the different types of undergraduate degrees?</h6>
<p>A: Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>

<h6>Q: What are the different types of graduate degrees?</h6>
<p>A: Masters: two-year degree providing additional specialization. Doctorate: five to eight-year program certifying the student as a trained research scholar and/or professor.</p>

<h6>Q: Is it possible to take a professional degree program without first earning a bachelor’s degree?</h6>
<p>A: Yes, but they are highly selective and require a heavy courseload across a total of six years of study.</p>

<h6>Q: Is it possible to obtain a bachelor’s degree and a master’s degree at the same time?</h6>
<p>A: In a joint-degree program, students begin a graduate program in their fourth year of college, earning both degrees upon graduation.</p>

<h6>Q: What is the length of study for MBA programs in the U.S.?</h6>
<p>A: MBA programs typically last one to two years.</p>

<h6>Q: What is the difference between online studies and distance studies?</h6>
<p>A: Essentially there is no difference.</p>

<h6>Q: What is distance education?</h6>
<p>A: Distance education occurs when a student and an instructor are in different places. Learning occurs by mail, telephone, internet, or by other means.</p>

<h6>Q: Is distance learning available at the graduate level?</h6>
<p>A: Yes. To find accredited online distance learning programs, please search the Distance Education Accrediting Commission website.</p>

<h6>Q: Can I transfer to a U.S. university from a university outside of the United States?</h6>
<p>A: Yes, although you may lose some credits and require extra time to complete your degree.</p>

<h6>Q: What is the transfer application process?</h6>
<p>A: You must fulfill the requirements of a freshman applicant, as well as any supplemental information required by the transfer institution.</p>

<h6>Q: What is a community college?</h6>
<p>A: Community colleges are typically state-supported and provide the first two years of a four-year undergraduate degree.</p>

<h6>Q: Why should I attend community college?</h6>
<p>A: Community colleges offer lower costs, easier admission policies, close ties to state schools, and many of the required courses connected to a degree.</p>

<h6>Q: How do you transfer from a community college to a four-year university?</h6>
<p>A: The transfer process varies for each school. It is best to target the four-year institution early and determine what is needed to transfer.</p>

<h6>Q: How can I find out if an institution is accredited?</h6>
<p>A: Search the U.S. Department of Education’s Office of Post-secondary Education website to see if an institution is accredited.</p>

<h6>Q: How can I find out which universities are rated best for a specific academic major?</h6>
<p>A: Refer to college and university guides to find which institutions are known for excellence in different fields of study.</p>

<h6>Q: What are English language proficiency requirements?</h6>
<p>A: U.S. universities require an English language proficiency test before admission to ensure you can read, write, and speak fluently.</p>

<h6>Q: I want to study in the United States, but my English proficiency isn’t good enough yet. What can I do?</h6>
<p>A: There are a number of programs for English language study in the United States and online, as well as local possibilities.</p>

<h6>Q: Do students have to study a fixed set of subjects or can they individually form their major?</h6>
<p>A: It depends: Some degree programs are highly structured. Bachelors’ degrees are very flexible and sometimes allow you to create your own program.</p>

<h6>Q: When do you declare a major?</h6>
<p>A: You do not declare a major until the end of your second year of school.</p>

<h6>Q: What is a liberal arts college?</h6>
<p>A: A liberal arts college offers courses in humanities, languages, math, social and natural sciences, and students take 25-50% of their courses in their major.</p>

<h6>Q: What are the benefits of a liberal arts education?</h6>
<p>A: A liberal arts college offers a smaller setting and teaches critical thinking and communication skills necessary in an ever-changing job market.</p>

<h6>Q: What is the credit system in U.S. universities?</h6>
<p>A: A credit is a value assigned to each course which reflects the number of hours the class will meet with the professor each week.</p>

<h6>Q: What is a GPA?</h6>
<p>A: Grade Point Average (GPA) is a numeric indicator for a student’s academic performance, calculated on a scale of 4.0.</p>

<h6>Q: How are grades determined in U.S. universities?</h6>
<p>A: Grades are typically determined by quizzes, midterms, final exams, papers, projects, class attendance, and class participation.</p>

<h6>Q: What is the difference between state and private universities?</h6>
<p>A: State universities are funded by the state and are generally larger and less expensive than private universities.</p>

<h6>Q: Are there opportunities for university exchange programs?</h6>
<p>A: Contact the office responsible for international programs at your institution to ask if your school has exchange agreements with U.S. universities.</p>

<h6>Q: How can an international student find out what academic subjects from their country are acceptable for a U.S. university?</h6>
<p>A: Each U.S. university will want to review an international student’s subjects and may ask the student to contact a credential evaluation agency.</p>

<h6>Q: How can I apply for vocational training in the United States?</h6>
<p>A: U.S. institutions cannot issue I-20 forms for non-degree study, including vocational training. Community colleges offer technical/vocational study for an associate’s degree.</p>

<h6>Q: What’s the best general advice for an incoming student?</h6>
<p>A: Attend the closest EducationUSA advising center’s predeparture orientation. Then, when you arrive on campus, attend all orientation meetings scheduled at your college or university.</p>


    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyEight">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyEight" aria-expanded="false" aria-controls="collapseThirtyEight">
    Universities accepting 15 years of Education
    </button>
  </h2>
  <div id="collapseThirtyEight" className="accordion-collapse collapse" aria-labelledby="headingThirtyEight" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <ul>
  <li>University of Dayton - Dayton, Ohio</li>
  <li>University of Illinois Springfield - Springfield, Illinois</li>
  <li>University of Massachusetts Boston - Boston, Massachusetts</li>
  <li>University of the Pacific - Northern California</li>
  <li>Adelphi University - Garden City, New York</li>
  <li>Oregon State University</li>
  <li>Drew University</li>
  <li>The University of Alabama at Birmingham</li>
  <li>Suffolk University</li>
  <li>Illinois State University</li>
  <li>Saint Louis University</li>
  <li>George Mason University</li>
  <li>Hofstra University</li>
  <li>Westcliff University</li>
  <li>Concordia University Chicago</li>
  <li>Florida Atlantic University</li>
</ul>

    </div>
  </div>
</div>

<div className="accordion-item">
  <h2 className="accordion-header" id="headingThirtyNine">
    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThirtyNine" aria-expanded="false" aria-controls="collapseThirtyNine">
    How can I check ranking of the USA Universities???
    </button>
  </h2>
  <div id="collapseThirtyNine" className="accordion-collapse collapse" aria-labelledby="headingThirtyNine" data-bs-parent="#accordionExample">
    <div className="accordion-body">
        <h6>Rankings are classified into following categories:</h6>
        <ul>
  <li>National Universities</li>
  <li>National Liberal Arts Colleges</li>
  <li>Regional Universities</li>
  <li>Regional Colleges</li>
  <li>Community Colleges</li>
  <li>All Rankings</li>
</ul>
 <p>You may refer the source: https://www.usnews.com/best-colleges/rankings/national-universities</p>
 <p>For Graduate Schools refer to the link:2023 Best Graduate Schools – US News</p>
    </div>
  </div>
</div>

  </div>
    </>
  )
}
