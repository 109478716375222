import React from 'react'
import gerimg1 from '../assets/newfolder/singaimg1.png'
import gerimg2 from '../assets/newfolder/ireimg2.png'
import gerimg3 from '../assets/newfolder/ireimg3.png'
import gerimg4 from '../assets/newfolder/ireimg4.png'
import gerimg5 from '../assets/newfolder/ireimg5.png'

export default function Singacomponent() {
  return (
    <>
    <div className="accordion" id="accordionExample">

    <div className="accordion-item">
    <h2 className="accordion-header" id="headingOne">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Map Of Singapore
      </button>
    </h2>
    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div className="accordion-body">
    <img src={gerimg1} className='img-fluid' alt="" />

      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingTwo">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      About Singapore
      </button>
    </h2>
    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <div className="mb-2">
        <h5>Capital</h5>
        <p>Officially the Republic of Singapore</p>
    </div>
    
    <div className="mb-2">
        <h5>Location</h5>
        <p>South East Asia, has 63 Islands</p>
    </div>
    
    <div className="mb-2">
        <h5>Population</h5>
        <p>5.6 million</p>
    </div>
    
    <div className="mb-2">
        <h5>Economy</h5>
        <p>Free Market Economy</p>
    </div>
    
    <div className="mb-2">
        <h5>Currency</h5>
        <p>SGD, Singapore Dollar</p>
    </div>
    
    <div className="mb-2">
        <h5>Official Languages</h5>
        <p>English, Malay, Mandarin, Tamil</p>
    </div>
    
    <div className="mb-2">
        <h5>Culture</h5>
        <p>Combination of Asian and European cultures. Influenced by Malay, South Asian, East Asian, and Eurasian cultures, Folk religion</p>
    </div>
    
    <div className="mb-2">
        <h5>Climate</h5>
        <p>Tropical Rainforest</p>
    </div>
    
    <div className="mb-2">
        <h5>Religion</h5>
        <p>Buddhism main religion, Christianity, Hinduism, Islam</p>
    </div>
    
    <div className="mb-2">
        <h5>Ethnic Groups</h5>
        <p>Chinese, Malay, Indian, Buddhism, Christianity, Islam, Taoism, Hinduism</p>
    </div>
    
    <div className="mb-2">
        <h5>Airport</h5>
        <p>Changi Airport</p>
    </div>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingThree">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Culture
      </button>
    </h2>
    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>CAPITAL OF SINGAPORE : Officially the Republic of Singapore</li>
        <li>LOCATION: South East Asia, has 63 Islands</li>
        <li>POPULATION: 5.6 million</li>
        <li>ECONOMY: Free Market Economy</li>
        <li>CURRENCY: SGD, Singapore Dollar</li>
        <li>OFFICIAL LANGUAGE : English, Malay,Mandarin, Tamil</li>
        <li>CULTURE:Combination of Asian and European cultures. Influenced by Malay, South Asian,</li>
        <li>East Asian, and Eurasian cultures, Folk religion</li>
        <li>CLIMATE: Tropical Rainforest,</li>
        <li>RELIGION: Budhhism main religion, Christianity, Hinduism, Islam</li>
      </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFour">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
      Currency
      </button>
    </h2>
    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>Currency of Singapore is the Singapore Dollar .</li>
        <li>1 SGD is equivalent to 52- 55 INR</li>
      </ul>
      </div> 
    </div>
  </div>

  <div className="accordion-item">
    <h2 className="accordion-header" id="headingFive">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
      Language
      </button>
    </h2>
    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Official language- English, Malay, Mandarin, Tamil, and recognized national language – Malay.</p>
      </div>
    </div>
  </div>


  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingSix">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
      Airport
      </button>
    </h2>
    <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>An International Airport of Singapore is Changi Airport</li>
        <li>It has 4 Terminals.</li>
        <li>Singapore Changi Airport is located 24km from the centre of the city. The journey should take around 23 minutes by taxi.</li>
      </ul>

      </div>
    </div>
  </div>



  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Why to Study</span> in Singapore ?</h4>
    </div>

    
  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingEight">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
      Why to Study in Singapore ?
      </button>
    </h2>
    <div id="collapseEight" className="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>Six reasons to study in Singapore:</p>
      <ul>
        <li>Top-notch education system
</li>
        <li>Education Grants
</li>
        <li>English speaking country
</li>
        <li>Lowest Crime rate – Safest and clean country
</li>
        <li>Affordable Cost
</li>
        <li>Immersion into Asian Century
</li>
      </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingSeven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
      Education System in Singapore
      </button>
    </h2>
    <div id="collapseSeven" className="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Education in Singapore is managed by the Ministry of Education (MOE), which controls the development and administration of state schools receiving taxpayers’ funding, but also has an advisory and supervisory role in respect of private schools. For both private and state schools, there are variations in the extent of autonomy in their curriculum, scope of taxpayers’ aid and funding, tuition burden on the students, and admission policy.</p>

      </div>
    </div>
  </div>

  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingNine">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
      Tution Grant
      </button>
    </h2>
    <div id="collapseNine" className="accordion-collapse collapse" aria-labelledby="headingNine" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <p>The Ministry of Education, Singapore (MOE) provides tuition grant (TG) which covers a substantial portion of the full tuition fees to eligible students. Students who receive the tuition grant are required to pay only the subsidized tuition fee</p>
      <ul>
        <li>Tuition grant agreement to be signed by non-Singapore Citizens.
</li>
        <li>Provided to National Universities
</li>
        <li>Polytechnics</li>
        <li>Art Institutions- Lasalle, Nanyang Academy of Fine Arts
</li>
      </ul>
      </div>
    </div>
  </div>



  <div className="section-head col-sm-12 mb-1 mt-3">
        <h4><span>Institutes in</span> Singapore</h4>
    </div>

    <div className="accordion-item border">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Types Of Universities in Singapore
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Singapore has National Universities, Institutes and Colleges.</p>
      <ul className="list-group">
        <li className="list-group-item">National University of Singapore (NUS)</li>
        <li className="list-group-item">Nanyang Technological University (NTU)</li>
        <li className="list-group-item">Singapore Management University (SMU)</li>
        <li className="list-group-item">Singapore University of Technology & Design (SUT)</li>
        <li className="list-group-item">Singapore Institute of Technology (SIT)</li>
        <li className="list-group-item">Singapore University of Social Sciences</li>
       
    </ul>
      </div>
    </div>
  </div>

  <div className="accordion-item border">
    <h2 className="accordion-header" id="headingTen">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
      Private Education Institutes (PEIs)
      </button>
    </h2>
    <div id="collapseTen" className="accordion-collapse collapse" aria-labelledby="headingTen" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      <ul>
        <li>LASALLE College of the Arts. Singapore. …
</li>
        <li>Informatics Academy. Singapore. …
</li>
        <li>Singapore Institute Of Management (SIM) Singapore. …</li>
        <li>James Cook University Singapore. Singapore. …
</li>
        <li>FTMSGlobal Academy. Singapore. …
</li>
        <li>INSEAD Asia Campus. Singapore.
</li>
        <li>Curtin University, Singapore
</li>
        <li>SP Jain School of Global Management
</li>
        <li>GIST- German Institute of Science and Technology – TUM Asia
</li>
        <li>Singapore Raffles Music College
</li>
        <li>Kaplan learning Institute
</li>
        <li>Management Development Institute of Science (MDIS)
</li>
        <li>PSB Academy
</li>
        <li>East Asia Institute Of Management
</li>
        <li>AEC College
</li>
        <li>Beacon International University
</li>
        <li>Stansfield College
</li>
        <li>Amity Global Business school
</li>
      </ul>
      </div>
    </div>
  </div>


  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingTwelwe">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelwe" aria-expanded="false" aria-controls="collapseTwelwe">
      Programs Offered
      </button>
    </h2>
    <div id="collapseTwelwe" className="accordion-collapse collapse" aria-labelledby="headingTwelwe" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        <p>Undergraduate Courses:</p>
       <ul>
        <li>MBA/ Business/ Management</li>
        <li>IT/ Computing
</li>
        <li>Engineering & Science
</li>
        <li>Tourism/ travel/ hotel management
</li>
        <li>Creative Arts/ Design Courses
</li>
        <li>Architecture</li>
       </ul>
      </div>
    </div>
  </div>


  <div className="accordion-item ">
    <h2 className="accordion-header" id="headingEleven">
      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
      Admission Crieteria
      </button>
    </h2>
    <div id="collapseEleven" className="accordion-collapse collapse" aria-labelledby="headingEleven" data-bs-parent="#accordionExample">
      <div className="accordion-body">
        
      <ul className="list-group">
        <li className="list-group-item">To be eligible for undergraduate admissions in Singapore, students will generally need to produce 10th and 12th scores, and must have IELTS and TOEFL scores of 6.5-7 or 90-100 to prove their English proficiency. If you want to apply for bachelors programmes at private universities, you must have a minimum score of 65% or above in your 12th grade</li>
        <li className="list-group-item">Please note IELTS is not mandatory especially if you are applying at private universities.</li>
        <li className="list-group-item">But if you apply for bachelors programmes at public universities, you must have 90% or above in both 10th and 12th grade. You must have your SOP/LOR with you when submitting applications at universities in Singapore.</li>
        <li className="list-group-item">To apply for masters programmes, you will need to submit 10th, 12th and bachelors mark sheets an SOP, 2LORs and a CV. IELTS is not required if you are applying at private universities, but both IELTS/TOEFL might be required for other universities.</li>
        <li className="list-group-item">For a few courses in Singapore, GRE/GMAT/SAT might be required. Moreover, students applying for masters in Singapore must hold a recognised bachelors degree with minimum 55% or above.</li>
    </ul>
      </div>
    </div>
  </div>

    </div>
    </>
  )
}
