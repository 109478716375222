'use client'

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from '../../assets/img/globedwiselogo.png'

// import 'bootstrap/dist/js/bootstrap';
// import("bootstrap");
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
// if (typeof window !== 'undefined') {
//   require('bootstrap/dist/js/bootstrap');
// }


export default function Headercopy() {
  useEffect(() => {
    require("bootstrap/dist/js/bootstrap.bundle.min.js");
  }, []);

 
  return (
    <>

    <div id="mainnavsec" className="sticky">




   <div className="navhead row py-2 mx-0 px-4">
    <div className="col-md-12" style={{display:'flex', flexFlow:'column' , justifyContent: 'center' , alignItems: 'center'}} >

    <div className="socialhead">
    <div className="socail1">
    <Link to="/"> <img src={logo} alt="globedwiselogo" style={{width:'220px'}}/></Link>
    </div>

    </div>

    <div className="menuhead col-md-12 mt-3">
      
    <h6 className="mainlinks"><Link className=" text-dark" to="/">Home</Link></h6> 
  
  <h6 className="mainlinks"><a className=" text-dark" target="_blank" href="https://www.globedwise.com/events">Upcoming Events</a></h6> 
  
    <h6 className="mainlinks"><Link className=" text-dark" to="/partner-institutions">Partner Institutions</Link></h6> 
  
    <h6 className="mainlinks"><Link className=" text-dark" to="/canada-program-availability">Canada Program Availability</Link></h6> 
  
    <h6 className="mainlinks"><Link className=" text-dark" to="/check-program-availability">Check Program Availability</Link></h6> 
  
    <h6 className="mainlinks"><Link className=" text-dark" to="/globedwise-packages">Globedwise Packages</Link></h6> 


          <div className="dropdown">
    <h5 className="mainlinks dropdown dropdown-toggle text-dark" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          More <i className="fa fa-angle-down" aria-hidden="true"></i>
          </h5>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item fw-bold" to="/">Shortlisting Tool</Link></li>
            
            <li className="py-1"><Link target="_blank" className="dropdown-item fw-bold" to="https://globedwise.com/services/finbee">Finbee</Link></li>
            
            <li className="py-1"><Link className="dropdown-item fw-bold" to="/contact-information">Contact Information</Link></li>

            <li className="py-1"><Link className="dropdown-item fw-bold" to="/admission-evaluator-tool">Admission Evaluator Tool</Link></li>
                        
            <li><Link className="dropdown-item fw-bold" to="/tutorials">Tutorials</Link></li>
          </ul>
          </div>

    </div>

    </div>
  </div>

  {/* <div className="alert alert-primary" role="alert" style={{zIndex:'99'}}>
  A simple primary alert—check it out!
</div> */}

</div>


    </>
  );
}
